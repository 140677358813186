var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _vm.user.role.isAdmin
        ? _c("div", { staticClass: "filter-container" }, [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.cityId,
                      callback: function ($$v) {
                        _vm.cityId = $$v
                      },
                      expression: "cityId",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Tất cả", value: null },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.cities, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          attrs: { accordion: false },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c("h3", [_vm._v("Đơn hàng")]),
              ]),
              _vm._v(" "),
              _c("div", [_c("order")], 1),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-collapse-item",
            { attrs: { name: "2" } },
            [
              _c("template", { slot: "title" }, [_c("h3", [_vm._v("Tài xế")])]),
              _vm._v(" "),
              _c("Driver"),
            ],
            2
          ),
          _vm._v(" "),
          _vm.user.role.isAdmin
            ? _c(
                "el-collapse-item",
                { attrs: { name: "3" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("h3", [_vm._v("Khách hàng")]),
                  ]),
                  _vm._v(" "),
                  _c("Customer"),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }