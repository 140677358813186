var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
            [_vm._v("\n      Thêm khuyến mãi\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "STT" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "Tiêu đề", prop: "title" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "Code", prop: "code" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", label: "Ngày bắt đầu", prop: "start" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDate(row.start)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", label: "Ngày kết thúc", prop: "end" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDate(row.end)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.type == _vm.ApproveType.Store ||
          _vm.type == _vm.ApproveType.Regional ||
          _vm.type == _vm.ApproveType.Restaurant
            ? _c("el-table-column", {
                attrs: {
                  label:
                    _vm.type == _vm.ApproveType.Restaurant
                      ? "Nhà hàng"
                      : "Cửa hàng",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(row.store ? row.store.name : "") +
                              _vm._s(
                                row.restaurant ? row.restaurant.name : ""
                              ) +
                              _vm._s(
                                row.storeRegional ? row.storeRegional.name : ""
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4009985399
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "250", label: "Điều kiện" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" },
                      },
                      [
                        row.conditionMinPrice
                          ? _c("li", [
                              _vm._v(
                                "\n            Theo giá: " +
                                  _vm._s(
                                    _vm.formatCurrency(row.conditionMinPrice)
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.conditionMinDistance
                          ? _c("li", [
                              _vm._v(
                                "\n            Theo Km: " +
                                  _vm._s(row.conditionMinDistance) +
                                  " KM\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", [_vm._v("Cấp bậc: " + _vm._s(row.level))]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Giảm giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.discountType != "MONEY"
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(row.discountValue) +
                              "%\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatCurrency(row.discountValue)) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("\n          Chi tiết\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0" },
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("\n          Xoá\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update"
                ? "Cập nhật khuyến mãi"
                : "Thêm khuyến mãi",
            visible: _vm.dialogVisible,
            width: "1200px",
            top: "15px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tiêu đề",
                                        prop: "title",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "title", $$v)
                                          },
                                          expression: "formData.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Code", prop: "code" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "code", $$v)
                                          },
                                          expression: "formData.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        label: "Ngày bắt đầu",
                                        prop: "start",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          "value-format": "timestamp",
                                          type: "date",
                                          placeholder: "Pick a day",
                                        },
                                        model: {
                                          value: _vm.formData.start,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "start", $$v)
                                          },
                                          expression: "formData.start",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Ngày kết thúc",
                                        prop: "end",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          "value-format": "timestamp",
                                          type: "date",
                                          placeholder: "Pick a day",
                                        },
                                        model: {
                                          value: _vm.formData.end,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "end", $$v)
                                          },
                                          expression: "formData.end",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.type == _vm.ApproveType.Store ||
                              _vm.type == _vm.ApproveType.Regional ||
                              _vm.type == _vm.ApproveType.Restaurant
                                ? [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _vm.type == _vm.ApproveType.Store
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Cửa hàng",
                                                  prop: "storeId",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData.storeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "storeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.storeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.stores,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.type == _vm.ApproveType.Regional
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Cửa hàng sản vật",
                                                  prop: "storeRegionalId",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .storeRegionalId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "storeRegionalId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.storeRegionalId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.stores,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.type == _vm.ApproveType.Restaurant
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Nhà hàng",
                                                  prop: "restaurantId",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .restaurantId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "restaurantId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.restaurantId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.stores,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                "Tổng bill thấp nhất (VNĐ)",
                                            },
                                          },
                                          [
                                            _c("input-money", {
                                              model: {
                                                value:
                                                  _vm.formData.conditionMinBill,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "conditionMinBill",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.conditionMinBill",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.type == _vm.ApproveType.OrderFood ||
                              _vm.type == _vm.ApproveType.OrderDelivery ||
                              _vm.type == _vm.ApproveType.OrderTransport
                                ? [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Giá thấp nhất(VNĐ)",
                                              prop: "conditionMinPrice",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formData
                                                      .conditionMinPrice,
                                                  expression:
                                                    "formData.conditionMinPrice",
                                                },
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: {
                                                    alias: "decimal",
                                                    groupSeparator: ".",
                                                    rightAlign: false,
                                                    autoGroup: true,
                                                    repeat: 20,
                                                    autoUnmask: true,
                                                    allowMinus: false,
                                                  },
                                                  expression:
                                                    "{\n                        alias: 'decimal',\n                        groupSeparator: '.',\n                        rightAlign: false,\n                        autoGroup: true,\n                        repeat: 20,\n                        autoUnmask: true,\n                        allowMinus: false,\n                      }",
                                                },
                                              ],
                                              attrs: {
                                                placeholder:
                                                  "Để trống sẽ không áp dụng",
                                                type: "text",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.formData
                                                    .conditionMinPrice,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "conditionMinPrice",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Km thấp nhất(Km)",
                                              prop: "conditionMinDistance",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formData
                                                      .conditionMinDistance,
                                                  expression:
                                                    "formData.conditionMinDistance",
                                                },
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: {
                                                    alias: "decimal",
                                                    groupSeparator: ".",
                                                    rightAlign: false,
                                                    autoGroup: true,
                                                    repeat: 20,
                                                    autoUnmask: true,
                                                    allowMinus: false,
                                                  },
                                                  expression:
                                                    "{\n                        alias: 'decimal',\n                        groupSeparator: '.',\n                        rightAlign: false,\n                        autoGroup: true,\n                        repeat: 20,\n                        autoUnmask: true,\n                        allowMinus: false,\n                      }",
                                                },
                                              ],
                                              attrs: {
                                                placeholder:
                                                  "Để trống sẽ không áp dụng",
                                                type: "text",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.formData
                                                    .conditionMinDistance,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "conditionMinDistance",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Loại khuyến mãi",
                                        prop: "discountType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: _vm.formData.discountType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "discountType",
                                                $$v
                                              )
                                            },
                                            expression: "formData.discountType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "Theo giá",
                                              value: "MONEY",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "Theo phần trăm",
                                              value: "PERCENT",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Giá trị khuyến mãi",
                                        prop: "discountValue",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.formData.discountValue,
                                            expression:
                                              "formData.discountValue",
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                            },
                                            expression:
                                              "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.formData.discountValue,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.formData,
                                              "discountValue",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Cấp bậc nhỏ nhất được nhận\n                ",
                                        prop: "level",
                                      },
                                    },
                                    [
                                      _c("input-money", {
                                        model: {
                                          value: _vm.formData.level,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "level", $$v)
                                          },
                                          expression: "formData.level",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [_vm._v("Tỷ lệ 177/117")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thumbnail",
                                        prop: "thumbnail",
                                      },
                                    },
                                    [
                                      _c("SingleImage", {
                                        attrs: {
                                          "path-image": _vm.formData.thumbnail,
                                          "upload-url": "/promotions/upload",
                                        },
                                        on: {
                                          "upload:success":
                                            _vm.handleUploadSuccess,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [_vm._v("Tỷ lệ 2/1")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Banner", prop: "image" },
                                    },
                                    [
                                      _c("SingleImage", {
                                        attrs: {
                                          "path-image": _vm.formData.image,
                                          "upload-url": "/promotions/upload",
                                        },
                                        on: {
                                          "upload:success":
                                            _vm.handleUploadSuccessImage,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mô tả", prop: "body" } },
                            [
                              _c("Tinymce", {
                                ref: "editor",
                                attrs: { width: "100%", height: 400 },
                                model: {
                                  value: _vm.formData.body,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "body", $$v)
                                  },
                                  expression: "formData.body",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }