"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var sharing = _interopRequireWildcard(require("@/api/sharing-request"));

var _format = require("@/utils/format");

var _enum = require("@/utils/enum");

var _CustomerDialog = _interopRequireDefault(require("@/views/customer/components/CustomerDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    CustomerDialog: _CustomerDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      tagOptions: _enum.tagOptions,
      SHARING_REQUEST_STATUS: _enum.SHARING_REQUEST_STATUS,
      SharingRequestStatus: _enum.SharingRequestStatus,
      PaymentType: _enum.PaymentType,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleCancel: function handleCancel(row) {
      var _this = this;

      this.$confirm("Yêu cầu này sẽ bị hủy, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this.listLoading = true;
        sharing.cancel(row.id).then(function () {
          _this.getTableData();

          _this.$message({
            type: "success",
            message: "Hủy thành công"
          });
        }).finally(function () {
          _this.listLoading = false;
        });
      });
    },
    getTableData: function getTableData() {
      var _this2 = this;

      this.listLoading = true;
      sharing.findAll(this.listQuery).then(function (res) {
        _this2.tableData = res.data.sharingRequests;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;