"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depositCustomerApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var depositCustomerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/depositCustomer",
      params: params
    });
  }
};
exports.depositCustomerApi = depositCustomerApi;