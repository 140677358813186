"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _product = require("@/api/product");

var _enterprise = require("@/api/enterprise");

var _ProductDialog = _interopRequireDefault(require("./components/ProductDialog"));

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

var _productCategory = require("@/api/product-category");

var _ProductTable = _interopRequireWildcard(require("./components/ProductTable.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    ProductDialog: _ProductDialog.default,
    ProductTable: _ProductTable.default
  },
  data: function data() {
    return {
      productCategories: [],
      enterprises: [],
      listLoading: false,
      total: 0,
      STORE_TYPE: enumConst.STORE_TYPE,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isPublish: null
      },
      levels: _ProductTable.levels,
      productSelected: {},
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
    this.fetchProductCategories();
    this.fetchEnterprises();
  },
  methods: {
    fetchEnterprises: function fetchEnterprises() {
      var _this = this;

      _enterprise.enterpriseApi.findAll().then(function (res) {
        return _this.enterprises = res.data.enterprises;
      });
    },
    fetchProductCategories: function fetchProductCategories() {
      var _this2 = this;

      _productCategory.productCategoryApi.findAll().then(function (res) {
        _this2.productCategories = res.data.productCategories;
      });
    },
    handleChangeLv: function handleChangeLv(row, level) {
      var _this3 = this;

      this.listLoading = true;

      _product.productApi.changeLv(row.id, {
        level: level
      }).then(function () {
        _this3.getTableData();
      }).finally(function () {
        return _this3.listLoading = false;
      });
    },
    unPublishProduct: function unPublishProduct(row) {
      var _this4 = this;

      this.listLoading = true;

      _product.productApi.unPublish(row.id).then(function () {
        return _this4.getTableData();
      }).finally(function () {
        return _this4.listLoading = false;
      });
    },
    publishProduct: function publishProduct(row) {
      var _this5 = this;

      this.listLoading = true;

      _product.productApi.publish(row.id).then(function () {
        return _this5.getTableData();
      }).finally(function () {
        return _this5.listLoading = false;
      });
    },
    handleChangePublish: function handleChangePublish(row) {
      if (row.isPublish) {
        return this.unPublishProduct(row);
      }

      return this.publishProduct(row);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleBlock: function handleBlock(row) {
      var _this6 = this;

      this.$confirm("Sản phẩm này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this6.listLoading = true;

        _product.productApi.remove(row.id).then(function () {
          _this6.getTableData();

          _this6.$message({
            type: "success",
            message: "Xóa thành công"
          });
        }).finally(function () {
          return _this6.listLoading = false;
        });
      });
    },
    getTableData: function getTableData() {
      var _this7 = this;

      this.listLoading = true;

      _product.productApi.findAll(this.listQuery).then(function (res) {
        _this7.tableData = res.data.products;
        _this7.total = res.data.total;
      }).finally(function () {
        _this7.listLoading = false;
      });
    }
  }
};
exports.default = _default;