"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

require("core-js/modules/es6.number.constructor");

var _ProductTable = _interopRequireDefault(require("@/views/product/components/ProductTable.vue"));

var _ProductDialog = _interopRequireDefault(require("@/views/product/components/ProductDialog.vue"));

var _product = require("@/api/product");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ProductTable: _ProductTable.default,
    ProductDialog: _ProductDialog.default
  },
  props: {
    enterpriseId: Number
  },
  data: function data() {
    return {
      listLoading: false,
      data: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  watch: {
    enterpriseId: function enterpriseId() {
      this.getTableData();
    }
  },
  methods: {
    handleChangeLv: function handleChangeLv(row, level) {
      var _this = this;

      this.listLoading = true;

      _product.productApi.changeLv(row.id, {
        level: level
      }).then(function () {
        _this.getTableData();
      }).finally(function () {
        return _this.listLoading = false;
      });
    },
    unPublishProduct: function unPublishProduct(row) {
      var _this2 = this;

      this.listLoading = true;

      _product.productApi.unPublish(row.id).then(function () {
        return _this2.getTableData();
      }).finally(function () {
        return _this2.listLoading = false;
      });
    },
    publishProduct: function publishProduct(row) {
      var _this3 = this;

      this.listLoading = true;

      _product.productApi.publish(row.id).then(function () {
        return _this3.getTableData();
      }).finally(function () {
        return _this3.listLoading = false;
      });
    },
    handleChangePublish: function handleChangePublish(row) {
      if (row.isPublish) {
        return this.unPublishProduct(row);
      }

      return this.publishProduct(row);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleBlock: function handleBlock(row) {
      var _this4 = this;

      this.$confirm("Sản phẩm này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this4.listLoading = true;

        _product.productApi.remove(row.id).then(function () {
          _this4.getTableData();

          _this4.$message({
            type: "success",
            message: "Xóa thành công"
          });
        }).finally(function () {
          return _this4.listLoading = false;
        });
      });
    },
    getTableData: function getTableData() {
      var _this5 = this;

      this.listLoading = true;

      _product.productApi.findAll((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        enterpriseId: this.enterpriseId
      })).then(function (res) {
        _this5.data = res.data.products;
        _this5.total = res.data.total;
      }).finally(function () {
        _this5.listLoading = false;
      });
    }
  }
};
exports.default = _default;