"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

require("core-js/modules/es6.number.constructor");

var order = _interopRequireWildcard(require("@/api/order/food"));

var driver = _interopRequireWildcard(require("@/api/driver"));

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _format = require("@/utils/format");

var _enum = require("@/utils/enum");

var _moment = _interopRequireDefault(require("moment"));

var _CustomerDialog = _interopRequireDefault(require("@/views/customer/components/CustomerDialog"));

var _DriverDialog = _interopRequireDefault(require("@/views/driver/components/DriverDialog"));

var _StoreDialog = _interopRequireDefault(require("@/views/store/components/StoreDialog"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    CustomerDialog: _CustomerDialog.default,
    DriverDialog: _DriverDialog.default,
    StoreDialog: _StoreDialog.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      totalRevenue: 0,
      totalIncome: 0,
      ORDER_FOOD_STATUS: _enum.ORDER_FOOD_STATUS,
      listLoading: false,
      total: 0,
      totalMoney: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null,
        status: null
      },
      tableData: [],
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      tagOptions: _enum.tagOptions
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCities: function addressCities(state) {
      return state.address.cities;
    },
    user: function user(state) {
      return state.user.info;
    }
  })),
  watch: {
    driverId: function driverId() {
      this.getTableDataByDriverId();
    }
  },
  created: function created() {
    if (!this.driverId) {
      this.getTableData();
    } else {
      this.getTableDataByDriverId();
    }
  },
  methods: {
    viewStore: function viewStore(store) {
      this.$refs["store-dialog"].handleEdit(store);
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    getTableDataByDriverId: function getTableDataByDriverId() {
      var _this = this;

      driver.orderFood(this.driverId, this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(row) {
      var _this2 = this;

      this.$confirm("Đơn này sẽ bị huỷ?", "Cảnh báo", {
        type: "warning",
        cancelButtonText: "Đóng"
      }).then(function () {
        _this2.listLoading = true;
        order.cancel(row.id).then(function () {
          _this2.$notify({
            type: "success",
            title: "Thông báo",
            message: "Thành công"
          });

          _this2.getTableData();
        }).finally(function () {
          _this2.listLoading = false;
        });
      });
    },
    getTotalMoney: function getTotalMoney() {
      var _this3 = this;

      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this3.totalMoney = total;
      });
    },
    getTableData: function getTableData() {
      var _this4 = this;

      this.listLoading = true;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      order.findAll(this.listQuery).then(function (res) {
        _this4.tableData = res.data.data;
        _this4.totalRevenue = res.data.totalRevenue;
        _this4.totalIncome = res.data.totalIncome;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;