"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _layout.default,
  redirect: "/dashboard/index",
  permission: true,
  meta: {
    title: "Dashboard",
    icon: "dashboard"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    name: "Dashboard",
    meta: {
      title: "Dashboard"
    }
  } // {
  //   path: 'report',
  //   component: () => import('@/views/dashboard/report'),
  //   name: 'Report',
  //   meta: {
  //     title: 'Báo cáo',
  //   }
  // },
  ]
}, {
  path: "/promotion",
  component: _layout.default,
  redirect: "/promotion/index",
  permission: true,
  meta: {
    title: "Mã khuyến mãi",
    icon: "ticket-alt-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion"));
      });
    },
    name: "Promotion",
    meta: {
      title: "Mã khuyến mãi"
    }
  }]
}, {
  path: "/store",
  component: _layout.default,
  redirect: "/store/index",
  permission: true,
  meta: {
    title: "Đặt đồ ăn",
    icon: "utensils-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store/index"));
      });
    },
    name: "Store",
    meta: {
      title: "Cửa hàng"
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/food/index"));
      });
    },
    name: "Food",
    meta: {
      title: "Đơn hàng"
    }
  }, {
    path: "food-keyword",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/food-keyword"));
      });
    },
    name: "config-food-keyword",
    meta: {
      title: "Từ khoá tìm kiếm"
    }
  }]
}, {
  path: "/transport",
  component: _layout.default,
  redirect: "/transport/index",
  permission: true,
  meta: {
    title: "Gọi xe ôm",
    icon: "taxi-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/transport/index"));
      });
    },
    name: "Transport",
    meta: {
      title: "Gọi xe ôm"
    }
  }]
}, {
  path: "/delivery",
  component: _layout.default,
  redirect: "/delivery/index",
  permission: true,
  meta: {
    title: "Giao hàng",
    icon: "box-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/delivery/index"));
      });
    },
    name: "Delivery",
    meta: {
      title: "Giao hàng"
    }
  }]
}, {
  path: "/sharing",
  component: _layout.default,
  redirect: "/sharing/index",
  permission: true,
  meta: {
    title: "Đi xe ghép",
    icon: "car-sharing"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/sharing/index"));
      });
    },
    name: "sharing",
    meta: {
      title: "Chuyến đi"
    }
  }, {
    path: "sharing-request",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sharing-request/index"));
      });
    },
    name: "Sharing Request",
    meta: {
      title: "Yêu cầu khách hàng"
    }
  }]
}, {
  path: "/scheck",
  component: _layout.default,
  redirect: "/scheck/index",
  permission: true,
  meta: {
    title: "Truy xuất",
    icon: "barcode-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/enterprise"));
      });
    },
    name: "enterprise",
    meta: {
      title: "Doanh nghiệp"
    }
  }, {
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product"));
      });
    },
    name: "product-list",
    meta: {
      title: "Sản phẩm"
    }
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-category"));
      });
    },
    name: "product-category",
    meta: {
      title: "Loại sản phẩm"
    }
  }, {
    path: "question",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-question"));
      });
    },
    name: "product-question",
    meta: {
      title: "Hỏi đáp"
    }
  }]
}, {
  path: "/restaurant",
  component: _layout.default,
  redirect: "/restaurant/index",
  permission: true,
  meta: {
    title: "Đặt bàn",
    icon: "food"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/restaurant"));
      });
    },
    name: "restaurant-index",
    meta: {
      title: "Nhà hàng"
    }
  }, {
    path: "restaurant",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/restaurant/index"));
      });
    },
    name: "Restaurant",
    meta: {
      title: "Đơn hàng"
    }
  }]
}, {
  path: "/regional",
  component: _layout.default,
  redirect: "/regional/index",
  permission: true,
  meta: {
    title: "Sản vật vùng miền",
    icon: "souvenir"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store-regional"));
      });
    },
    name: "store-regional-index",
    meta: {
      title: "Cửa hàng"
    }
  }, {
    path: "regional",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/regional/index"));
      });
    },
    name: "Regional",
    meta: {
      title: "Đơn hàng"
    }
  }]
}, {
  path: "/customer",
  component: _layout.default,
  redirect: "/customer/index",
  permission: true,
  meta: {
    title: "Khách hàng",
    icon: "peoples"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "Customer",
    meta: {
      title: "Danh sách khách hàng"
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-customer"));
      });
    },
    name: "customer-deposit",
    meta: {
      title: "Nạp tiền"
    }
  }]
}, {
  path: "/drvier",
  component: _layout.default,
  redirect: "/driver/index",
  permission: true,
  meta: {
    title: "Tài xế",
    icon: "car-fill"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver/index"));
      });
    },
    name: "Driver",
    meta: {
      title: "Danh sách tài xế"
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit/index"));
      });
    },
    name: "Deposit",
    meta: {
      title: "Nạp tiền"
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw/index"));
      });
    },
    name: "Withdraw",
    meta: {
      title: "Rút tiền"
    }
  }]
}, {
  path: "/media",
  component: _layout.default,
  redirect: "/media/banner",
  permission: true,
  meta: {
    title: "Truyền thông",
    icon: "skill"
  },
  children: [{
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner-store"));
      });
    },
    name: "Banner",
    meta: {
      title: "Banner"
    }
  }, {
    path: "content",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content/index"));
      });
    },
    name: "Content",
    meta: {
      title: "Nội dung"
    }
  }, {
    path: "marketing",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/marketing"));
      });
    },
    name: "marketing",
    meta: {
      title: "Marketing (Chiến dịch)"
    }
  }, {
    path: "maccaca-plus",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/news-maccaca-plus"));
      });
    },
    name: "Maccaca-plus",
    meta: {
      title: "Maccaca+"
    }
  }, {
    path: "maccaca-coffee",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/news-maccaca-coffee"));
      });
    },
    name: "Maccaca-coffee",
    meta: {
      title: "Maccaca Coffee"
    }
  }]
}, {
  path: "/config",
  component: _layout.default,
  redirect: "/config/commission",
  meta: {
    title: "Cấu hình",
    icon: "setting-fill"
  },
  permission: true,
  children: [{
    path: "commission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/commission"));
      });
    },
    name: "Config",
    meta: {
      title: "Chung"
    }
  }, {
    path: "order-type",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/order-type"));
      });
    },
    name: "order-type",
    meta: {
      title: "Phí ship"
    }
  }, {
    path: "service-city",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service-city"));
      });
    },
    name: "config-service-city",
    meta: {
      title: "Dịch vụ"
    }
  }, {
    path: "badge",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/badge"));
      });
    },
    name: "badge",
    meta: {
      title: "Cấp bậc"
    }
  }, {
    path: "affiliate",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/affiliate"));
      });
    },
    name: "affiliate",
    meta: {
      title: "Affiliate"
    }
  }, {
    path: "driver-reward",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reward"));
      });
    },
    name: "driver-reward",
    meta: {
      title: "Thưởng tài xế"
    }
  }]
}, {
  path: "/staff",
  component: _layout.default,
  redirect: "/staff/index",
  permission: true,
  meta: {
    title: "Nhân viên",
    icon: "user"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user"));
      });
    },
    name: "staff-index",
    meta: {
      title: "Danh sách nhân viên"
    }
  }]
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  permission: false,
  meta: {
    title: "Cá nhân",
    icon: "qq"
  },
  children: [{
    permission: false,
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      permission: false,
      title: "Trang cá nhân"
    }
  }]
}, {
  path: "/permission",
  component: _layout.default,
  redirect: "/permission/role",
  // hidden: true,
  meta: {
    title: "Quyền",
    icon: "guide"
  },
  permission: true,
  children: [{
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "permission-role",
    meta: {
      title: "Danh sách quyền"
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [// 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;