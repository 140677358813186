"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _BarChartCustom = _interopRequireDefault(require("../BarChartCustom"));

var dashbord = _interopRequireWildcard(require("@/api/dashboard"));

var _moment = _interopRequireDefault(require("moment"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCityId: function addressCityId(state) {
      return state.address.citySelectedId;
    }
  })),
  components: {
    BarChart: _BarChartCustom.default
  },
  data: function data() {
    return {
      dataChart: []
    };
  },
  watch: {
    date: {
      handler: function handler() {
        this.getDataChart();
      },
      deep: true,
      immediate: true
    },
    addressCityId: function addressCityId() {
      this.getDataChart();
    }
  },
  props: ["date"],
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;

      var listQuery = {
        from: this.date[0],
        to: this.date[1],
        addressCityId: this.addressCityId
      };
      dashbord.orderTransportChart(listQuery).then(function (res) {
        _this.dataChart = res.data.report;

        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};
exports.default = _default;