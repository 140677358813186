"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var driver = _interopRequireWildcard(require("@/api/driver"));

var _format = require("@/utils/format");

var _vuex = require("vuex");

var _OrderHistory = _interopRequireDefault(require("./OrderHistory"));

var _PayHistory = _interopRequireDefault(require("./PayHistory"));

var _TransactionHistory = _interopRequireDefault(require("./TransactionHistory"));

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "DriverDialog",
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrderHistory: _OrderHistory.default,
    PayHistory: _PayHistory.default,
    TransactionHistory: _TransactionHistory.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCities: function addressCities(state) {
      return state.address.cities;
    },
    user: function user(state) {
      return state.user.info;
    }
  })), (0, _vuex.mapGetters)(["token"])),
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "order-history":
          this.dialogWith = "1300px";
          break;

        case "info":
          this.dialogWith = "600px";
          break;

        case "pay-history":
          this.dialogWith = "1300px";
          break;

        case "transaction-history":
          this.dialogWith = "1300px";
          break;

        default:
          break;
      }
    }
  },
  data: function data() {
    return {
      VehicleType: _enum.VehicleType,
      dialogWith: "600px",
      tabName: "info",
      dialogLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      tempData: {},
      avatar: "",
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      roles: [],
      roleId: 1,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        username: {
          required: true,
          message: "Vui lòng nhập tài khoản",
          trigger: "submit"
        },
        phone: {
          required: true,
          message: "Vui lòng nhập số điện thoại",
          trigger: "submit"
        },
        password: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nhập mật khẩu"
        },
        licensePlate: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nhập biển số"
        },
        addressCityId: {
          required: true,
          message: "Vui lòng chọn tỉnh thành",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    errorUpload: function errorUpload(err) {
      this.dialogLoading = false;
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    editData: function editData() {
      var _this = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            driver: _this.tempData,
            addressCityId: _this.tempData.addressCityId
          };
          _this.loadingButton = true;
          driver.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$message({
                type: "success",
                message: "Cập nhật thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();

        _this2.tempData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
          addressCityId: data.addressCity && data.addressCity.id
        });
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.tabName = "info";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].resetFields();

        _this3.tempData = {};
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            driver: _this4.tempData,
            addressCityId: _this4.tempData.addressCityId
          };
          _this4.loadingButton = true;
          driver.create(data).then(function (res) {
            if (res.status) {
              _this4.$message({
                type: "success",
                message: "Thêm tài xế thành công"
              });

              _this4.dialogVisible = false;

              _this4.$refs["dataForm"].resetFields();

              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.dialogLoading = false;
      this.$set(this.tempData, "avatar", res.data.path);
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }

      return isJPG;
    },
    getData: function getData(userId) {
      var _this5 = this;

      this.dialogVisible = true;
      this.dialogStatus = "edit";
      user.findOne(userId).then(function (res) {
        _this5.tempData = res.data;
      });
    }
  },
  created: function created() {
    this.$store.dispatch("address/fetchCities");
  }
};
exports.default = _default;