var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "table_dialog",
        title: "Chi tiết yêu cầu xe đi ghép",
        visible: _vm.dialogVisible,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.formData.sharingRequests, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Khách hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.customer
                      ? _c("span", [_vm._v("--")])
                      : _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.viewCustomer(row.customer)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.customer.name) +
                                "\n        "
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.tagOptions[row.status].type,
                              effect: _vm.tagOptions[row.status].effect,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.SHARING_REQUEST_STATUS[row.status]) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Điểm đi", prop: "startAddress" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Điểm đến", prop: "endAddress" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Khoảng cách", prop: "distance" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatNumberVN(row.distance)) +
                        " Km\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              "min-width": "300px",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Tiền khách nhập:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            _vm._s(
                              row.moneyCustomer
                                ? _vm.formatCurrency(row.moneyCustomer)
                                : "Thương lượng"
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Tiền tài xế nhập:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyDriver))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Phương thức thanh toán:")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.PaymentType[row.paymentType])),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CustomerDialog", {
        ref: "customer-dialog",
        attrs: { "dialog-disabled": true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }