"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.affiliateApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var affiliateApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: "/affiliateLevel"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/affiliateLevel/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  }
};
exports.affiliateApi = affiliateApi;