"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _enum = require("@/utils/enum");

var _date = require("@/utils/date");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      tagOptions: _enum.tagOptions,
      OrderTableStatus: _enum.OrderTableStatus,
      PaymentType: _enum.PaymentType,
      btnLoading: false,
      fileList: [],
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      imageSelected: "",
      previewModal: false
    };
  },
  methods: {
    handleView: function handleView(order) {
      var _this = this;

      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, order);
      this.fileList = order.orderRegionalBills.map(function (e) {
        return {
          url: _this.baseUrlMedia + e.thumbnail
        };
      });
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    },
    calcSumPrice: function calcSumPrice(data) {
      var sum = data.reduce(function (prev, cur) {
        return prev + cur.amount * cur.finalPrice;
      }, 0);
      return sum;
    },
    handlePreview: function handlePreview(file) {
      this.imageSelected = file.url;
      this.previewModal = true;
    }
  }
};
exports.default = _default;