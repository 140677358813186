var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        { staticClass: "request-list" },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "info" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.customer.name)),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "date" }, [
                      _vm._v(_vm._s(_vm.$formatDate(item.dateCreated))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-rate", {
                    attrs: { disabled: "", "text-color": "#ff9900" },
                    model: {
                      value: item.rateStar,
                      callback: function ($$v) {
                        _vm.$set(item, "rateStar", $$v)
                      },
                      expression: "item.rateStar",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.rateComment) + "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              index != _vm.data.length - 1 ? _c("el-divider") : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }