var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customer.name" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đánh giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-rate", {
                      attrs: {
                        value: row.rateStar,
                        disabled: "",
                        "text-color": "#ff9900",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(row.rateComment))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }