var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 20 } },
    [
      _c("el-col", { staticClass: "card-panel-col", attrs: { span: 24 } }, [
        _c("div", { staticClass: "card-panel" }, [
          _c(
            "div",
            { staticClass: "card-panel-icon-wrapper icon-money" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "people-carry", size: "3x" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-panel-description" },
            [
              _c("div", { staticClass: "card-panel-text" }, [_vm._v("Tài xế")]),
              _vm._v(" "),
              _c("span", [_vm._v("Tổng:")]),
              _vm._v(" "),
              _c("count-to", {
                staticClass: "card-panel-num",
                attrs: {
                  "start-val": 0,
                  "end-val": _vm.data.totalDriver,
                  duration: 3200,
                },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", [_vm._v("Hôm nay:")]),
              _vm._v(" "),
              _c("count-to", {
                staticClass: "card-panel-num",
                attrs: {
                  "start-val": 0,
                  "end-val": _vm.data.totalDriverToday,
                  duration: 3200,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-col", { attrs: { span: 24 } }, [
        _c("div", { staticClass: "chart-wrapper" }, [_c("Top5Driver")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }