"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _OrderFood = _interopRequireDefault(require("./OrderFood"));

var _OrderDelivery = _interopRequireDefault(require("./OrderDelivery"));

var _OrderTransport = _interopRequireDefault(require("./OrderTransport"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    driverId: Number
  },
  components: {
    OrderFood: _OrderFood.default,
    OrderDelivery: _OrderDelivery.default,
    OrderTransport: _OrderTransport.default
  }
};
exports.default = _default;