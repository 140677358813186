"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _date = require("@/utils/date");

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

var _marketingStrategy = require("@/api/marketing-strategy");

var _CustomerTable = _interopRequireDefault(require("./CustomerTable"));

var _marketing = require("@/api/marketing");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CustomerTable: _CustomerTable.default
  },
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      MarketingStrategyMethod: enumConst.MarketingStrategyMethod,
      dialogWidth: "500px",
      typeVehicles: [],
      dialogLoading: false,
      tempData: {
        title: "",
        body: ""
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        method: {
          required: true,
          message: "Vui lòng chọn phương thức",
          trigger: "submit"
        },
        title: {
          required: true,
          message: "Vui lòng nhập tiêu đề",
          trigger: "submit"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nội dung"
        }
      }
    };
  },
  computed: {
    renderTitle: function renderTitle() {
      switch (this.dialogStatus) {
        case "add":
          return "Tạo chiến dịch";

        case "edit":
          return "Cập nhật chiến dịch";

        case "process":
          return "Thực thi chiến dịch";

        default:
          break;
      }
    }
  },
  methods: {
    submitProcess: function submitProcess(query) {
      var _this = this;

      var data = {};
      data.category = query.category;

      switch (query.category) {
        case "BAGE":
          data.level = query.level;
          break;

        case "OFFLINE":
          data.offlineDays = query.offlineDays;
          break;
      }

      this.loadingButton = true;
      (0, _marketing.create)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        marketingStrategyId: this.tempData.id
      })).then(function () {
        _this.$notify.success({
          title: "Thông báo",
          message: "Thưc thi thành công"
        });

        _this.dialogVisible = false;
      }).finally(function () {
        return _this.loadingButton = false;
      });
    },
    process: function process() {
      this.$refs["customer-table"].handleProcess();
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();
      });
    },
    handleEdit: function handleEdit(data) {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "edit";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();

        _this3.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleProcess: function handleProcess(data) {
      var _this4 = this;

      this.dialogVisible = true;
      this.dialogStatus = "process";
      this.$nextTick(function () {
        _this4.$refs["dataForm"].clearValidate();

        _this4.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    editData: function editData() {
      var _this5 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this5.loadingButton = true;

          _marketingStrategy.marketingStrategyApi.update(_this5.tempData.id, {
            marketingStrategy: _this5.tempData
          }).then(function (res) {
            if (res.status) {
              _this5.$notify({
                type: "success",
                title: "Thông báo",
                message: "Cập nhật thành công"
              });

              _this5.dialogVisible = false;

              _this5.$emit("create:success");
            }
          }).finally(function () {
            _this5.loadingButton = false;
          });
        }
      });
    },
    createData: function createData() {
      var _this6 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this6.loadingButton = true;

          _marketingStrategy.marketingStrategyApi.create({
            marketingStrategy: _this6.tempData
          }).then(function (res) {
            if (res.status) {
              _this6.$notify({
                type: "success",
                title: "Thông báo",
                message: "Tạo thành công"
              });

              _this6.dialogVisible = false;

              _this6.$emit("create:success");
            }
          }).finally(function () {
            _this6.loadingButton = false;
          });
        }
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;