"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.levels = exports.default = void 0;

var _vueBarcode = _interopRequireDefault(require("vue-barcode"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var levels = [{
  label: "Cấp 1",
  value: 1
}, {
  label: "Cấp 2",
  value: 2
}, {
  label: "Cấp 3",
  value: 3
}, {
  label: "Cấp 4",
  value: 4
}];
exports.levels = levels;
var _default = {
  components: {
    VueBarcode: _vueBarcode.default
  },
  props: {
    tableData: Array,
    listLoading: Boolean,
    handleEdit: Function,
    handleBlock: Function,
    handleChangePublish: Function,
    handleChangeLv: Function
  },
  data: function data() {
    return {
      levels: levels
    };
  },
  methods: {
    calcStar: function calcStar(row) {
      var res = row.totalStar / row.rates;
      if (isNaN(res)) return 0;
      return res.toFixed(1);
    }
  }
};
exports.default = _default;