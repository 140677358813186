"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancel = cancel;
exports.findAll = findAll;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orderDelivery",
    method: "get",
    params: listQuery
  });
}

function update(orderId, data) {
  return (0, _request.default)({
    url: "/orderDelivery/".concat(orderId, "/update"),
    method: "post",
    data: data
  });
}

function cancel(orderId) {
  return (0, _request.default)({
    url: "/orderDelivery/".concat(orderId, "/cancel"),
    method: "post"
  });
}