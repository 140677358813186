var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "store-dialog" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "30px",
            title:
              _vm.dialogStatus == "add" ? "Thêm sản phẩm" : "Cập nhật sản phẩm",
            visible: _vm.dialogVisible,
            width: _vm.modalWidth,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { name: "info", label: "Thông tin sản phẩm" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-position": "top",
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tên", prop: "name" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Doanh nghiệp(ĐVSX)",
                                    prop: "enterpriseId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.form.enterpriseId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "enterpriseId",
                                            $$v
                                          )
                                        },
                                        expression: "form.enterpriseId",
                                      },
                                    },
                                    _vm._l(_vm.enterprises, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Loại sản phẩm",
                                    prop: "categoryId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.form.categoryId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "categoryId", $$v)
                                        },
                                        expression: "form.categoryId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.productCategories,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Bar code", prop: "barCode" },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.barCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "barCode", $$v)
                                      },
                                      expression: "form.barCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Giá", prop: "price" } },
                                [
                                  _c("input-money", {
                                    staticStyle: {
                                      height: "32px",
                                      transform: "translateY(1px)",
                                    },
                                    model: {
                                      value: _vm.form.price,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "price", $$v)
                                      },
                                      expression: "form.price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Xuất xứ", prop: "origin" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.origin,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "origin", $$v)
                                      },
                                      expression: "form.origin",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: { flex: ".7", "margin-right": "10px" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mô tả" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 4 },
                                  model: {
                                    value: _vm.form.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "description", $$v)
                                    },
                                    expression: "form.description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Quy trình sản xuất (Link youtube)",
                                  prop: "productionProcess",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.productionProcess,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "productionProcess",
                                        $$v
                                      )
                                    },
                                    expression: "form.productionProcess",
                                  },
                                }),
                                _vm._v(" "),
                                true
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "center",
                                          margin: "8px",
                                        },
                                      },
                                      [
                                        _c("iframe", {
                                          attrs: {
                                            width: "300",
                                            height: "200",
                                            src:
                                              "https://www.youtube.com/embed/" +
                                              _vm.getIdVideo,
                                            frameborder: "0",
                                            allow:
                                              "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                                            allowfullscreen: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { flex: "1" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Sản xuất tại" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.produceAt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "produceAt", $$v)
                                    },
                                    expression: "form.produceAt",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mua hàng online(Link)" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.buyOnline,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "buyOnline", $$v)
                                    },
                                    expression: "form.buyOnline",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mua hàng sendo(Link)" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.sendo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sendo", $$v)
                                    },
                                    expression: "form.sendo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mua hàng tiki(Link)" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.tiki,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "tiki", $$v)
                                    },
                                    expression: "form.tiki",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mua hàng lazada(Link)" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.lazada,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lazada", $$v)
                                    },
                                    expression: "form.lazada",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mua hàng shopee(Link)" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.shopee,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "shopee", $$v)
                                    },
                                    expression: "form.shopee",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { label: "Ảnh" },
                                },
                                [
                                  _c("SingleImage", {
                                    attrs: {
                                      width: "100%",
                                      "upload-url": "/product/upload",
                                      "path-image": _vm.form.thumbnail,
                                    },
                                    on: {
                                      "upload:success": _vm.handleUploadSuccess,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Danh sách ảnh" } },
                                [_c("Galleries", { ref: "galleries" })],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: { label: "Chứng từ sản phẩm", name: "quanlities" },
                    },
                    [_c("Quantities", { attrs: { qualities: _vm.qualities } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Danh sách cửa hàng", name: "store" } },
                    [
                      _c("StoreTable", {
                        ref: "enterpriseStore",
                        attrs: {
                          "enterprise-id": _vm.form.enterpriseId,
                          "product-id": _vm.form.id,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Đánh giá", name: "rate" } },
                    [
                      _c("RateProduct", {
                        attrs: { "product-id": _vm.form.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Hỏi đáp", name: "question" } },
                    [
                      _c("ProductQuestion", {
                        attrs: { "product-id": _vm.form.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Lượt xem", name: "view" } },
                    [
                      _c("ViewCountTable", {
                        attrs: { "product-id": _vm.form.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _vm.tabName == "info" ||
              _vm.tabName == "quanlities" ||
              _vm.tabName == "store"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingButton },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus == "edit"
                            ? _vm.editData()
                            : _vm.createData()
                        },
                      },
                    },
                    [_vm._v("Đồng ý")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }