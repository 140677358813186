"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.VehicleType = exports.TransactionType = exports.TYPE_CONTENT_DEFINE = exports.SharingRequestStatus = exports.STORE_TYPE = exports.SHARING_REQUEST_STATUS = exports.PaymentType = exports.PARAMS = exports.OrderTableStatus = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_TABLE_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.NewsStoreType = exports.MarketingStrategyStatus = exports.MarketingStrategyMethod = exports.DriverStatus = exports.DepositDriverType = exports.DepositDriverStatus = exports.CustomerCategory = exports.CategoryFood = exports.ApproveType = void 0;
var STORE_TYPE = {
  FOOD: "Thức ăn",
  DRINK: "Thức uống"
};
exports.STORE_TYPE = STORE_TYPE;
var ORDER_FOOD_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
exports.ORDER_FOOD_STATUS = ORDER_FOOD_STATUS;
var ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
exports.ORDER_DELIVERY_STATUS = ORDER_DELIVERY_STATUS;
var ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang đi",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế",
  CUSTOMER_CANCEL: "Khách huỷ",
  BOOKING: "Đặt lịch"
};
exports.ORDER_TRANSPORT_STATUS = ORDER_TRANSPORT_STATUS;
var SHARING_REQUEST_STATUS = {
  REQUEST: "Yêu cầu",
  PENDING: "Đang chờ",
  ACCEPT: "Chấp nhận",
  DELIVERING: "Đang chạy",
  COMPLETE: "Hoàn thành",
  CUSTOMER_CANCEL: "Hủy"
};
exports.SHARING_REQUEST_STATUS = SHARING_REQUEST_STATUS;
var TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  HOW_TO_DEPOSIT: "Hướng dẫn nạp điểm",
  HOW_TO_USE_DRIVER: "Cách sử dụng (Tài xế)",
  FAQ_DRIVER: "Câu hỏi thường gặp (Tài xế)",
  BADGE_POINT: "Cách tính điểm"
};
exports.TYPE_CONTENT_DEFINE = TYPE_CONTENT_DEFINE;
var ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển xe máy",
  DELIVERY: "Giao hàng",
  TRANSPORT_CAR_7: "Di chuyển ô tô 7 chỗ",
  TRANSPORT_CAR_4: "Di chuyển ô tô 4 chỗ"
};
exports.ORDER_TYPE = ORDER_TYPE;
var PARAMS = {
  COMMISSION_FOOD: "Thức ăn",
  COMMISSION_DELIVERY: "Giao hàng",
  COMMISSION_TRANSPORT: "Di chuyển ",
  MIN_DRIVER_BALANCE: "Tài khoản tối thiểu",
  DISTANCE_FIND_DRIVER: "Khoảng cách tìm tài xế",
  RATE_POINT: "Tỷ lệ quy đổi",
  REWARD_RATE_STORE: "Thưởng đánh giá cửa hàng",
  REWARD_RATE_ORDER: "Thưởng đánh giá đơn hàng",
  POINT_ORDER_FOOD: "Điểm đơn đặt thức ăn",
  // Diem don thuc an
  POINT_ORDER_TRANSPORT: "Điểm đơn gọi xe",
  // Diem don goi xe
  POINT_ORDER_DELIVERY: "Điểm đơn giao hàng",
  // Diem don giao hang
  ANNUAL_FEE_STORE: "Phí thường niên cửa hàng",
  // Phi thuong nien
  COMMISSION_STORE: "Chiết khấu cửa hàng",
  // Chiet khau cua hang
  SERVICE_FEE_PARTNER: "Phí dịch vụ với quán ăn là partner",
  // Phi dich vu cua hang partner
  SERVICE_FEE_NOT_PARTNER: "Phí dịch vụ với quán ăn không là partner" // Phi dich vu cua hang partner

};
exports.PARAMS = PARAMS;
var tagOptions = {
  NOT_FOUND_DRIVER: {
    effect: "plain",
    type: "danger"
  },
  WAITING: {
    type: "warning",
    effect: "plain"
  },
  ACCEPT_ORDER: {
    type: "warning",
    effect: "plain"
  },
  DELIVERING: {
    type: "",
    effect: "plain"
  },
  DRIVER_CANCEL: {
    type: "danger",
    effect: "plain"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  },
  CUSTOMER_CANCEL: {
    type: "danger",
    effect: "dark"
  },
  FIND_DRIVER: {
    type: "danger",
    effect: "dark"
  },
  CANCEL: {
    type: "danger",
    effect: "dark"
  },
  PENDING: {
    type: "warning",
    effect: "plain"
  },
  BOOKING: {
    type: "warning",
    effect: "plain"
  },
  ACCEPT: {
    type: "warning",
    effect: "plain"
  },
  REQUEST: {
    type: "warning",
    effect: "plain"
  },
  CASHBACK: {
    type: "success",
    effect: "dark"
  },
  REJECT: {
    type: "danger",
    effect: "dark"
  }
};
exports.tagOptions = tagOptions;
var VehicleType = {
  BIKE: "Môtô 2 bánh",
  CAR_4: "Ôtô 4 chỗ",
  CAR_7: "Ôtô 7 chỗ"
};
exports.VehicleType = VehicleType;
var NewsStoreType = {
  Plus: "PLUS",
  Coffee: "COFFEE"
};
exports.NewsStoreType = NewsStoreType;
var TransactionType = {
  Deposit: "DEPOSIT",
  Withdraw: "WITHDRAW",
  Income: "INCOME"
};
exports.TransactionType = TransactionType;
var CategoryFood = {
  VEGETARIAN: "Món chay",
  SNACK: "Ăn vặt",
  RICE: "Cơm",
  MILK_TEA: "Trà sữa"
};
exports.CategoryFood = CategoryFood;
var CustomerCategory = {
  ALL: "Tất cả",
  BIRTHDAY_IN_MONTH: "Sinh nhật trong tháng",
  OFFLINE: "Không hoạt động",
  FEMALE: "Nữ",
  BADGE: "Cấp bậc"
};
exports.CustomerCategory = CustomerCategory;
var DepositDriverStatus = {
  PENDING: "Đang xử lý",
  FAIL: "Thất bại",
  COMPLETE: "Thành công"
};
exports.DepositDriverStatus = DepositDriverStatus;
var DepositDriverType = {
  DRIVER: "Nạp bằng ví điện tử ",
  STAFF: "Nạp bằng tiền mặt"
};
exports.DepositDriverType = DepositDriverType;
var DriverStatus = {
  FREE: "Đang hoạt động",
  BUSY: "Không hoạt động",
  HAVING_ORDER: "Đang có đơn"
};
exports.DriverStatus = DriverStatus;
var MarketingStrategyMethod = {
  PUSH_NOTIFICATION: "Thông báo qua app"
};
exports.MarketingStrategyMethod = MarketingStrategyMethod;
var MarketingStrategyStatus = {
  PENDING: "Chưa thực thi",
  COMPLETE: "Đã thực thi"
};
exports.MarketingStrategyStatus = MarketingStrategyStatus;
var ORDER_TABLE_STATUS = {
  PENDING: "Đang đợi",
  ACCEPT_ORDER: "Nhận đơn",
  COMPLETE: "Hoàn thành",
  CUSTOMER_CANCEL: "Hủy đơn",
  REJECT: "Từ chối",
  CASHBACK: "Hoàn tiền"
};
exports.ORDER_TABLE_STATUS = ORDER_TABLE_STATUS;
var OrderTableStatus = {
  Pending: "PENDING",
  AcceptOrder: "ACCEPT_ORDER",
  Complete: "COMPLETE",
  CustomerCancel: "CUSTOMER_CANCEL",
  Reject: "REJECT",
  CashBack: "CASHBACK"
};
exports.OrderTableStatus = OrderTableStatus;
var SharingRequestStatus = {
  Request: "REQUEST",
  Pending: "PENDING",
  Accept: "ACCEPT",
  Delivering: "DELIVERING",
  Complete: "COMPLETE",
  CustomerCancel: "CUSTOMER_CANCEL"
};
exports.SharingRequestStatus = SharingRequestStatus;
var PaymentType = {
  CASH: "Tiền mặt",
  POINT: "Điểm"
};
exports.PaymentType = PaymentType;
var ApproveType = {
  Store: "STORE",
  Restaurant: "RESTAURANT",
  Regional: "REGIONAL",
  OrderFood: "ORDER_FOOD",
  OrderDelivery: "ORDER_DELIVERY",
  OrderTransport: "ORDER_TRANSPORT"
};
exports.ApproveType = ApproveType;