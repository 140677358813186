"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _MarketingDialog = _interopRequireDefault(require("./components/MarketingDialog"));

var api = _interopRequireWildcard(require("@/api/badge"));

var _marketingStrategy = require("@/api/marketing-strategy");

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    MarketingDialog: _MarketingDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      MarketingStrategyMethod: _enum.MarketingStrategyMethod,
      listQuery: {
        page: 1,
        limit: 10
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    handleEdit: function handleEdit(row) {
      this.$refs.dialog.handleEdit(row);
    },
    handleProcess: function handleProcess(row) {
      this.$refs.dialog.handleProcess(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd(this.listQuery);
    },
    getTableData: function getTableData() {
      var _this = this;

      this.listLoading = true;

      _marketingStrategy.marketingStrategyApi.findAll(this.listQuery).then(function (res) {
        _this.tableData = res.data.marketingStrategies;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;