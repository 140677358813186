import { render, staticRenderFns } from "./OrderRegionalDialog.vue?vue&type=template&id=19162217&"
import script from "./OrderRegionalDialog.vue?vue&type=script&lang=js&"
export * from "./OrderRegionalDialog.vue?vue&type=script&lang=js&"
import style0 from "./OrderRegionalDialog.vue?vue&type=style&index=0&id=19162217&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19162217')) {
      api.createRecord('19162217', component.options)
    } else {
      api.reload('19162217', component.options)
    }
    module.hot.accept("./OrderRegionalDialog.vue?vue&type=template&id=19162217&", function () {
      api.rerender('19162217', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/OrderRegionalDialog.vue"
export default component.exports