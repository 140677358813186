var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Phí vận chuyển" } },
            [
              _c(
                "el-tabs",
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Gọi đồ ăn" } },
                    [
                      _c("Promotion", {
                        attrs: { type: _vm.ApproveType.OrderFood },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Gọi xe" } },
                    [
                      _c("Promotion", {
                        attrs: { type: _vm.ApproveType.OrderTransport },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Vận chuyển" } },
                    [
                      _c("Promotion", {
                        attrs: { type: _vm.ApproveType.OrderDelivery },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Cửa hàng" } },
            [_c("Promotion", { attrs: { type: _vm.ApproveType.Store } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Nhà hàng" } },
            [_c("Promotion", { attrs: { type: _vm.ApproveType.Restaurant } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Sản vật" } },
            [_c("Promotion", { attrs: { type: _vm.ApproveType.Regional } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }