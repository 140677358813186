var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "30px",
            title: _vm.renderTitle,
            visible: _vm.dialogVisible,
            width: _vm.dialogWidth,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    disabled: _vm.dialogDisabled,
                    rules: _vm.rules,
                    model: _vm.tempData,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tiêu đề", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.dialogStatus == "process",
                                },
                                model: {
                                  value: _vm.tempData.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "title", $$v)
                                  },
                                  expression: "tempData.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nội dung", prop: "body" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.dialogStatus == "process",
                                  type: "textarea",
                                },
                                model: {
                                  value: _vm.tempData.body,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "body", $$v)
                                  },
                                  expression: "tempData.body",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                disabled: _vm.dialogStatus == "process",
                                label: "Phương thức",
                                prop: "method",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "",
                                    disabled: _vm.dialogStatus == "process",
                                  },
                                  model: {
                                    value: _vm.tempData.method,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "method", $$v)
                                    },
                                    expression: "tempData.method",
                                  },
                                },
                                _vm._l(
                                  _vm.MarketingStrategyMethod,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: index },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Chú thích" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.dialogStatus == "process",
                                  type: "textarea",
                                  placeholder: "",
                                },
                                model: {
                                  value: _vm.tempData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "description", $$v)
                                  },
                                  expression: "tempData.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "process"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.process } },
                    [_vm._v("Thực thi")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "process"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingButton },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus == "edit"
                            ? _vm.editData()
                            : _vm.createData()
                        },
                      },
                    },
                    [_vm._v("Tạo chiến dịch")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("CustomerTable", {
        ref: "customer-table",
        on: { process: _vm.submitProcess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }