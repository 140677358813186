var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _vm._l(_vm.qualities, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "item light-box-shadow" },
          [
            _c("img", {
              staticStyle: { height: "250px", width: "100%" },
              attrs: { src: _vm.$baseUrlMedia + item.thumbnail, alt: "" },
            }),
            _vm._v(" "),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 2,
                placeholder: "VD: Chứng nhận vietgap",
              },
              model: {
                value: item.name,
                callback: function ($$v) {
                  _vm.$set(item, "name", $$v)
                },
                expression: "item.name",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mask" }, [
              _c(
                "span",
                {
                  staticClass: "icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.handleView(item)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-zoom-in" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(index)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              ),
            ]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "Xem ảnh",
            visible: _vm.previewModal,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: {
                width: "350px",
                height: "300px",
                "object-fit": "contain",
              },
              attrs: { src: _vm.$baseUrlMedia + _vm.imageSelected, alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.previewModal = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("SingleImage", {
        staticClass: "item",
        attrs: { width: "200px", "upload-url": "/product/upload" },
        on: { "upload:success": _vm.handleUploadQuantity },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }