"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var dashbord = _interopRequireWildcard(require("@/api/dashboard"));

var _format = require("@/utils/format");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      orderType: "orderFood",
      orderTypes: [{
        name: "Thức ăn",
        key: "orderFood"
      }, {
        name: "Giao hàng",
        key: "orderDelivery"
      }, {
        name: "Di chuyển",
        key: "orderTransport"
      }],
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCityId: function addressCityId(state) {
      return state.address.citySelectedId;
    }
  })),
  watch: {
    addressCityId: function addressCityId() {
      this.getTableData();
    }
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this = this;

      var params = {
        addressCityId: this.addressCityId
      };
      dashbord.top5Driver(params).then(function (res) {
        _this.tableData = res.data.top5;
      });
    }
  }
};
exports.default = _default;