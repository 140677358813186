"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PanThumb: _PanThumb.default
  },
  data: function data() {
    return {
      user: {},
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  created: function created() {
    this.user = _store.default.getters.info;
  }
};
exports.default = _default;