"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _enum = require("@/utils/enum");

var _date = require("@/utils/date");

var _format = require("@/utils/format");

var _CustomerDialog = _interopRequireDefault(require("@/views/customer/components/CustomerDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CustomerDialog: _CustomerDialog.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      tagOptions: _enum.tagOptions,
      SHARING_REQUEST_STATUS: _enum.SHARING_REQUEST_STATUS,
      PaymentType: _enum.PaymentType,
      btnLoading: false,
      fileList: [],
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      imageSelected: "",
      previewModal: false,
      listLoading: false
    };
  },
  methods: {
    handleView: function handleView(order) {
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, order);
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    }
  }
};
exports.default = _default;