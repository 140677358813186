var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "info" }, [
        _c("span", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.item.customer.name)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.$formatDate(_vm.item.dateCreated))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm._v("\n    " + _vm._s(_vm.item.question) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.item.answer
        ? _c(
            "div",
            { staticClass: "reply" },
            [
              _c("el-tag", { attrs: { type: "primary", effect: "plain" } }, [
                _vm._v("\n      Trả lời\n    "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-reply" }, [
                _c("div", { staticClass: "info" }, [
                  _c("div", [
                    _c("span", { staticClass: "name" }, [_vm._v("Admin")]),
                    _vm._v(" "),
                    !_vm.item.isEdit
                      ? _c(
                          "span",
                          {
                            staticClass: "edit",
                            staticStyle: {
                              color: "#1b75bc",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(_vm.item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-edit" })]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(_vm._s(_vm.$formatDate(_vm.item.answerAt))),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.item.isEdit
                  ? _c("div", { staticClass: "content" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.item.answer) + "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _c("div", [
            !_vm.item.isEdit
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      cursor: "pointer",
                      "font-size": "12px",
                      color: "#409EFF",
                      "font-weight": "bold",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleEdit(_vm.item)
                      },
                    },
                  },
                  [_vm._v("Trả lời")]
                )
              : _vm._e(),
          ]),
      _vm._v(" "),
      _vm.item.isEdit
        ? [
            _c("el-input", {
              attrs: { placeholder: "", type: "textarea" },
              model: {
                value: _vm.item.tmpAnswer,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "tmpAnswer", $$v)
                },
                expression: "item.tmpAnswer",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-top": "8px" },
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-position",
                  loading: _vm.item.loading,
                },
                on: {
                  click: function ($event) {
                    return _vm.reply(_vm.item)
                  },
                },
              },
              [_vm._v("\n      Trả lời\n    ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-top": "8px" },
                attrs: { type: "danger", size: "mini", icon: "el-icon-close" },
                on: {
                  click: function ($event) {
                    return _vm.cancelEdit(_vm.item)
                  },
                },
              },
              [_vm._v("\n      Huỷ\n    ")]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }