"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var dashboard = _interopRequireWildcard(require("@/api/dashboard"));

var _date = require("@/utils/date");

var _Order = _interopRequireDefault(require("./components/Order"));

var _Driver = _interopRequireDefault(require("./components/Driver"));

var _Customer = _interopRequireDefault(require("./components/Customer"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "DashboardAdmin",
  components: {
    Order: _Order.default,
    Driver: _Driver.default,
    Customer: _Customer.default
  },
  data: function data() {
    return {
      activeNames: ["1"],
      cityId: null
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    cities: function cities(state) {
      return state.address.cities;
    },
    user: function user(state) {
      return state.user.info;
    }
  })),
  watch: {
    cityId: function cityId(val) {
      this.$store.commit("address/setCitySelectedId", val);
    }
  },
  created: function created() {
    this.$store.dispatch("address/fetchCities");
  },
  methods: {}
};
exports.default = _default;