"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enterpriseApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var enterpriseApi = {
  findAll: function findAll(listQuery) {
    return (0, _request.default)({
      url: "/enterprise",
      method: "get",
      params: listQuery
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/enterprise",
      method: "post",
      data: data
    });
  },
  update: function update(enterpriseId, data) {
    return (0, _request.default)({
      url: "/enterprise/".concat(enterpriseId, "/update"),
      method: "post",
      data: data
    });
  },
  findRate: function findRate(enterpriseId, params) {
    return (0, _request.default)({
      url: "/enterprise/".concat(enterpriseId, "/rate"),
      params: params
    });
  },
  resetPassword: function resetPassword(enterpriseId, data) {
    return (0, _request.default)({
      url: "/enterprise/".concat(enterpriseId, "/password/update"),
      method: "post",
      data: data
    });
  }
};
exports.enterpriseApi = enterpriseApi;