"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _date = require("@/utils/date");

var _affiliate = require("@/api/affiliate");

var _AffiliateDialog = _interopRequireDefault(require("./components/AffiliateDialog"));

var _format = require("@/utils/format");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AffiliateDialog: _AffiliateDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this = this;

      this.$confirm("Khách hàng này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          customer: {
            isBlock: false
          }
        };
        customer.update(row.id, data).then(function () {
          row.isBlock = false;

          _this.$message({
            type: "success",
            message: "Mở khóa khách hàng thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this2 = this;

      this.$confirm("Khách hàng này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          customer: {
            isBlock: true
          }
        };
        customer.update(row.id, data).then(function () {
          row.isBlock = true;

          _this2.$message({
            type: "success",
            message: "Khóa khách hàng thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;

      this.listLoading = true;

      _affiliate.affiliateApi.findAll().then(function (res) {
        _this3.tableData = res.data.data;
        _this3.total = res.data.total;
      }).finally(function () {
        _this3.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;