var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "technician-table",
      attrs: { data: _vm.tableData, border: "", fit: "" },
    },
    [
      _c("el-table-column", {
        attrs: { "class-name": "text-center", type: "index", width: "50" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Tên", width: "250" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "info-box" }, [
                  _c("div", { staticClass: "info" }, [
                    _c(
                      "span",
                      {
                        staticClass: "name",
                        staticStyle: { "font-size": "16px" },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(row.name))]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#F56C6C",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatNumberVN(row.price)) +
                                " đ"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-rate", {
                          attrs: {
                            value: _vm.calcStar(row),
                            disabled: "",
                            "show-score": "",
                            "text-color": "#ff9900",
                            "score-template": "{value}",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "10px" } }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("toThousandFilter")(row.view))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-view" }),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: _vm.$baseUrlMedia + row.thumbnail, alt: "" },
                  }),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          label: "Barcode",
          prop: "barCode",
          width: "190",
          align: "center",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("VueBarcode", {
                  attrs: { width: 1, height: 30, value: row.barCode },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Cấp" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-select",
                  {
                    attrs: { value: row.level, placeholder: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleChangeLv(row, $event)
                      },
                    },
                  },
                  _vm._l(_vm.levels, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Loại", prop: "productCategory.name" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Đơn vị sản xuất", prop: "enterprise.name" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Duyệt" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-switch", {
                  attrs: {
                    value: row.isPublish,
                    "active-color": "#13ce66",
                    "inactive-color": "#ff4949",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleChangePublish(row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Ngày tạo" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", [_vm._v(_vm._s(_vm.$formatDate(row.dateCreated)))]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          label: "Hành động",
          width: "150",
          "class-name": "small-padding fixed-width",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEdit(row)
                      },
                    },
                  },
                  [_vm._v("\n        Chi tiết\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleBlock(row)
                      },
                    },
                  },
                  [_vm._v("\n        Xóa\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }