"use strict";

var _interopRequireWildcard3 = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _date = require("@/utils/date");

var enumConst = _interopRequireWildcard3(require("@/utils/enum"));

var customer = _interopRequireWildcard3(require("@/api/customer"));

var _Affiliate = _interopRequireDefault(require("./Affiliate"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    OrderHistory: function OrderHistory() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderHistory"));
      });
    },
    Affiliate: _Affiliate.default
  },
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogWidth: "500px",
      tabName: "info",
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      typeVehicles: [],
      dialogLoading: false,
      tempData: {
        type: "",
        openTime: "",
        closeTime: "",
        lat: 0,
        long: 0
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        gender: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn giới tính"
        },
        dayOfBirth: {
          required: true,
          message: "Vui lòng chọn ngày sinh",
          trigger: "submit"
        }
      }
    };
  },
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "info":
          this.dialogWidth = "500px";
          break;

        case "Affiliate":
          this.dialogWidth = "800px";
          break;

        default:
          this.dialogWidth = "1300px";
          break;
      }
    }
  },
  methods: {
    editData: function editData() {
      var _this = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            customer: _this.tempData
          };
          _this.loadingButton = true;
          customer.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật khách hàng thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();

        _this2.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            store: _this4.tempData
          };
          _this4.loadingButton = true;
          store.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo cửa hàng thành công"
              });

              _this4.dialogVisible = false;

              _this4.$refs["dataForm"].clearValidate();

              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;