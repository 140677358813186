"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    customerId: Number
  },
  components: {
    OrderFood: function OrderFood() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderFood"));
      });
    },
    OrderDelivery: function OrderDelivery() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderDelivery"));
      });
    },
    OrderTransport: function OrderTransport() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderTransport"));
      });
    }
  }
};
exports.default = _default;