"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productQuestionApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productQuestionApi = {
  findAll: function findAll(listQuery) {
    return (0, _request.default)({
      url: "/productQuestion",
      method: "get",
      params: listQuery
    });
  },
  pending: function pending(listQuery) {
    return (0, _request.default)({
      url: "/productQuestion/pending",
      method: "get",
      params: listQuery
    });
  }
};
exports.productQuestionApi = productQuestionApi;