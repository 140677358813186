"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var api = _interopRequireWildcard(require("@/api/badge"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tinymce: _Tinymce.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        phone: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số điện thoại"
        },
        banner: {
          required: true,
          trigger: "submit",
          message: "Vui lòng upload banner"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả"
        }
      },
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      formData: {
        type: "",
        openTime: "",
        closeTime: "",
        lat: 0,
        long: 0
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false
    };
  },
  methods: {
    createData: function createData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.submitLoading = true;
          var data = {
            badge: _this.formData
          };
          api.create(data).then(function () {
            _this.$message({
              type: "success",
              message: "Thêm thành công"
            });

            _this.$emit("create:success");

            _this.dialogVisible = false;
          }).finally(function () {
            _this.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this2.submitLoading = true;
          var data = {
            badge: _this2.formData
          };
          api.update(_this2.formData.id, data).then(function () {
            _this2.$message({
              type: "success",
              message: "Cập nhật thành công"
            });

            _this2.$emit("update:success");

            _this2.dialogVisible = false;
          }).finally(function () {
            _this2.submitLoading = false;
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();

        _this3.formData = (0, _objectSpread2.default)({}, row);
      });
    },
    handleCreate: function handleCreate() {
      var _this4 = this;

      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this4.$refs["form"].clearValidate();

        _this4.formData = {};
      });
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }

      return isJPG;
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.formData.thumbnail = res.data.path;
      this.dialogLoading = false;
    }
  }
};
exports.default = _default;