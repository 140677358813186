var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "store" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm tên sản phẩm")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm tên sản phẩm",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Chọn cửa hàng" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.isPublish,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isPublish", $$v)
                    },
                    expression: "listQuery.isPublish",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Đã duyệt", value: true },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Chưa duyệt", value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Loại SP")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.categoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "categoryId", $$v)
                    },
                    expression: "listQuery.categoryId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.productCategories, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Đ.vị sản xuất")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.enterpriseId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "enterpriseId", $$v)
                    },
                    expression: "listQuery.enterpriseId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.enterprises, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Cấp")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.level,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "level", $$v)
                    },
                    expression: "listQuery.level",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.levels, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getTableData },
            },
            [_vm._v("\n      Tìm\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("\n      Thêm\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ProductTable", {
        attrs: {
          tableData: _vm.tableData,
          listLoading: _vm.listLoading,
          handleEdit: _vm.handleEdit,
          handleBlock: _vm.handleBlock,
          handleChangePublish: _vm.handleChangePublish,
          handleChangeLv: _vm.handleChangeLv,
        },
      }),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("ProductDialog", {
        ref: "dialog",
        on: { "submit:success": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }