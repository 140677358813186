var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm code, tên, sđt",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "daterange",
              "range-separator": "To",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Ngày bắt đầu",
              "end-placeholder": "Ngày kết thúc",
            },
            on: { change: _vm.getTableData },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("\n      Tìm\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Code", prop: "code", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.status].type,
                          effect: _vm.tagOptions[row.status].effect,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.ORDER_TABLE_STATUS[row.status]) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Nhà hàng", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.restaurant
                      ? _c("span", [_vm._v("--")])
                      : [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewRestaurant(row.restaurant)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.restaurant.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thông tin", prop: "code", width: "auto" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "food-list",
                        staticStyle: { "padding-left": "0" },
                      },
                      [
                        _c("li", { staticClass: "food-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v("Tên khách hàng:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(_vm._s(row.customer.name)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "food-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v("SĐT:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(_vm._s(row.customer.phone)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "food-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v("Thời gian hẹn:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                _vm._s(row.hour) +
                                  ", " +
                                  _vm._s(_vm.formatDate(row.date))
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "food-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v("Phương thức thanh toán:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(_vm._s(_vm.PaymentType[row.paymentType])),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "food-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v("Tình trạng hóa đơn:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "amount" },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type: row.isBillVerified
                                        ? "success"
                                        : "danger",
                                      effect: row.isBillVerified
                                        ? "dark"
                                        : "plain",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.isBillVerified
                                          ? "Đã xác nhận"
                                          : "Chưa xác nhận"
                                      ) + "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              width: "auto",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticClass: "food-list" },
                      _vm._l(row.orderTableDetails, function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "food-item" },
                          [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.restaurantFood.name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "amount" }, [
                                _vm._v(" x " + _vm._s(item.amount)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", { staticClass: "single-price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        item.restaurantFood.finalPrice
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", { staticClass: "price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousandFilter")(
                                      item.amount * item.finalPrice
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "text-align": "right" } }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tổng cộng:"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toThousandFilter")(
                              _vm.calcSumPrice(row.orderTableDetails)
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Ngày tạo",
              prop: "dateCreated",
              align: "right",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.viewDetail(row)
                          },
                        },
                      },
                      [_vm._v("\n          Chi tiết\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "8px" } },
                      [
                        row.isBillVerified && !row.isCashBack
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCashback(row.id)
                                  },
                                },
                              },
                              [_vm._v("\n            Hoàn tiền\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    row.status != _vm.OrderTableStatus.CustomerCancel &&
                    row.status != _vm.OrderTableStatus.Complete &&
                    row.status != _vm.OrderTableStatus.CashBack
                      ? _c("div", { staticStyle: { "margin-top": "8px" } }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "8px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancel(row.id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Hủy đơn\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("OrderTableDialog", { ref: "order-dialog" }),
      _vm._v(" "),
      _c("RestaurantModal", {
        ref: "restaurant-dialog",
        attrs: { "dialog-disabled": true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }