"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var store = _interopRequireWildcard(require("@/api/history-payment-fee"));

var config = _interopRequireWildcard(require("@/api/commission"));

var _StoreDialog = _interopRequireDefault(require("./StoreDialog"));

var _format = require("@/utils/format");

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    StoreDialog: _StoreDialog.default
  },
  props: ["type", "fetchData"],
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      STORE_TYPE: enumConst.STORE_TYPE,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: ""
      },
      date: (0, _moment.default)().format("MM/YYYY"),
      tableData: [],
      dialogLoading: false,
      submitLoading: false,
      formData: {},
      dialogVisible: false,
      dialogStatus: "create",
      rules: {
        month: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn tháng"
        }
      },
      annualFee: 0
    };
  },
  watch: {
    fetchData: function fetchData(val) {
      if (val) {
        this.getTableData();

        if (this.type == "unpaid") {
          this.$emit("update:success", false);
        }
      }
    }
  },
  created: function created() {
    this.getTableData();
    this.getFee();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    getTableData: function getTableData() {
      if (this.type == "paid") {
        this.getTableDataPaid();
      } else {
        this.getTableDataUnpaid();
      }
    },
    getTableDataUnpaid: function getTableDataUnpaid() {
      var _this = this;

      this.listLoading = true;
      store.unpaid((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        month: this.date
      })).then(function (res) {
        _this.tableData = res.data.stores;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    getTableDataPaid: function getTableDataPaid() {
      var _this2 = this;

      this.listLoading = true;
      store.paid((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        month: this.date
      })).then(function (res) {
        _this2.tableData = res.data.histories;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    },
    handleAddFee: function handleAddFee(row) {
      var _this3 = this;

      this.dialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();

        _this3.formData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
          money: _this3.annualFee,
          month: (0, _moment.default)().format("MM/YYYY")
        });
      });
    },
    getFee: function getFee() {
      var _this4 = this;

      config.findOne({
        param: "ANNUAL_FEE_STORE"
      }).then(function (res) {
        _this4.annualFee = res.data;
      }).finally(function () {});
    },
    createData: function createData() {
      var _this5 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this5.submitLoading = true;
          var data = {
            month: _this5.formData.month,
            money: _this5.formData.money
          };
          store.create(_this5.formData.id, data).then(function () {
            _this5.$message({
              type: "success",
              message: "Đóng phí thành công"
            });

            _this5.$emit("update:success", true);

            _this5.dialogVisible = false;
          }).finally(function () {
            _this5.submitLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;