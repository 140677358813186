"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Customer = _interopRequireDefault(require("./chart/Customer"));

var _Top5Customer = _interopRequireDefault(require("./rank/Top5Customer"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Customer: _Customer.default,
    Top5Customer: _Top5Customer.default
  }
};
exports.default = _default;