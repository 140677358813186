"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _enterprise = require("@/api/enterprise");

var _EnterpriseDialog = _interopRequireDefault(require("./components/EnterpriseDialog"));

var _format = require("@/utils/format");

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    EnterpriseDialog: _EnterpriseDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      STORE_TYPE: enumConst.STORE_TYPE,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    changePriority: function changePriority(row) {
      var _this = this;

      store.update(row.id, {
        store: {
          priority: row.priority
        }
      }).then(function () {
        _this.getTableData();

        _this.$notify({
          title: "Thông báo",
          type: "success",
          message: "Cập nhật thành công!"
        });
      });
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this2 = this;

      this.$confirm("Cửa hàng này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          store: {
            isBlock: false
          }
        };
        store.update(row.id, data).then(function () {
          row.isBlock = false;

          _this2.$message({
            type: "success",
            message: "Mở khóa cửa hàng thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this3 = this;

      this.$confirm("Cửa hàng này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          store: {
            isBlock: true
          }
        };
        store.update(row.id, data).then(function () {
          row.isBlock = true;

          _this3.$message({
            type: "success",
            message: "Khóa cửa hàng thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this4 = this;

      this.listLoading = true;

      _enterprise.enterpriseApi.findAll(this.listQuery).then(function (res) {
        _this4.tableData = res.data.enterprises;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    handleReset: function handleReset(row) {
      var _this5 = this;

      this.$prompt("Nhập mật khẩu mới để khôi phục", "Khôi phục mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy"
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          newPassword: value
        };

        _enterprise.enterpriseApi.resetPassword(row.id, data).then(function (res) {
          _this5.$notify({
            type: "success",
            message: "Khôi phục mật khẩu thành công"
          });
        });
      });
    }
  }
};
exports.default = _default;