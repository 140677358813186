"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Promotion = _interopRequireDefault(require("./Promotion"));

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Promotion: _Promotion.default
  },
  data: function data() {
    return {
      ApproveType: _enum.ApproveType
    };
  }
};
exports.default = _default;