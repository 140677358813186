"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    SingleImage: _SingleImage.default
  },
  props: {
    qualities: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      previewModal: false,
      imageSelected: ""
    };
  },
  methods: {
    handleDelete: function handleDelete(index) {
      this.qualities.splice(index, 1);
    },
    handleView: function handleView(item) {
      this.imageSelected = item.thumbnail;
      this.previewModal = true;
    },
    handleUploadQuantity: function handleUploadQuantity(path) {
      this.qualities.push({
        name: "",
        thumbnail: path
      });
    }
  }
};
exports.default = _default2;