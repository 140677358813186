var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
        visible: _vm.dialogVisible,
        width: "600px",
        top: "15px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "text-center",
                  attrs: { label: "Banner", prop: "thumbnail" },
                },
                [
                  _c("span", [_vm._v("Tỷ lệ 414/186")]),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        name: "file",
                        drag: "",
                        "before-upload": _vm.beforeUpload,
                        action: _vm.baseUrl + "/bannerStore/upload",
                        "on-success": _vm.handleUploadSuccess,
                        "show-file-list": false,
                        headers: { token: _vm.token },
                      },
                    },
                    [
                      !_vm.formData.thumbnail
                        ? [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(
                                "\n              Kéo thả vào đây hoặc\n              "
                              ),
                              _c("em", [_vm._v("click vào để upload banner")]),
                            ]),
                          ]
                        : _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              "object-fit": "cover",
                            },
                            attrs: {
                              src: _vm.baseUrlMedia + _vm.formData.thumbnail,
                              alt: "",
                            },
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }