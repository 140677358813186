"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _storeRegional = require("@/api/store-regional");

var _StoreRegionalModal = _interopRequireDefault(require("./components/StoreRegionalModal"));

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    StoreRegionalModal: _StoreRegionalModal.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      STORE_TYPE: enumConst.STORE_TYPE,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    changePriority: function changePriority(row) {
      var _this = this;

      store.update(row.id, {
        store: {
          priority: row.priority
        }
      }).then(function () {
        _this.getTableData();

        _this.$notify({
          title: "Thông báo",
          type: "success",
          message: "Cập nhật thành công!"
        });
      });
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;

      this.$confirm("Nhà hàng này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this2.listLoading = true;

        _storeRegional.storeRegionalApi.delete(row.id).then(function () {
          _this2.getTableData();

          _this2.$message({
            type: "success",
            message: "Xóa thành công"
          });
        }).finally(function () {
          _this2.listLoading = false;
        });
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;

      this.listLoading = true;

      _storeRegional.storeRegionalApi.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.storeRegionals;
        _this3.total = res.data.total;
      }).finally(function () {
        _this3.listLoading = false;
      });
    },
    handleReset: function handleReset(row) {
      var _this4 = this;

      this.$prompt("Nhập mật khẩu mới để khôi phục", "Khôi phục mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy"
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          password: value
        };
        _this4.listLoading = true;

        _storeRegional.storeRegionalApi.updatePassword(row.id, data).then(function (res) {
          _this4.$notify({
            type: "success",
            message: "Khôi phục mật khẩu thành công"
          });
        }).finally(function () {
          _this4.listLoading = false;
        });
      });
    }
  }
};
exports.default = _default;