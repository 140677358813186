var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "chart-wrapper" }, [_c("Top5Customer")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "chart-wrapper" }, [_c("Customer")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }