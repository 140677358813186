"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var dashboardApi = _interopRequireWildcard(require("@/api/dashboard"));

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

var _Top5Driver = _interopRequireDefault(require("./rank/Top5Driver"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CountTo: _vueCountTo.default,
    Top5Driver: _Top5Driver.default
  },
  data: function data() {
    return {
      data: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCityId: function addressCityId(state) {
      return state.address.citySelectedId;
    }
  })),
  watch: {
    addressCityId: function addressCityId() {
      this.fetchData();
    }
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      var params = {
        addressCityId: this.addressCityId
      };
      dashboardApi.driver(params).then(function (res) {
        _this.data = res.data;
      });
    }
  },
  created: function created() {
    this.fetchData();
  }
};
exports.default = _default;