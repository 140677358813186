"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

var _affiliate = require("@/api/affiliate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogWidth: "500px",
      dialogLoading: false,
      tempData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        percent: {
          required: true,
          message: "Vui lòng nhập phần trăm",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    editData: function editData() {
      var _this = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            affiliateLevel: _this.tempData
          };
          _this.loadingButton = true;

          _affiliate.affiliateApi.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();

        _this2.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            store: _this4.tempData
          };
          _this4.loadingButton = true;
          store.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo cửa hàng thành công"
              });

              _this4.dialogVisible = false;

              _this4.$refs["dataForm"].clearValidate();

              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    }
  }
};
exports.default = _default;