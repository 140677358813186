"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var user = _interopRequireWildcard(require("@/api/user"));

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _UserDialog = _interopRequireDefault(require("./components/UserDialog"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    UserDialog: _UserDialog.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCities: function addressCities(state) {
      return state.address.cities;
    },
    user: function user(state) {
      return state.user.info;
    }
  })),
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    handleReset: function handleReset(row) {
      var _this = this;

      this.$prompt("Nhập mật khẩu mới để khôi phục", "Khôi phục mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy"
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          newPassword: value
        };
        user.reset(row.id, data).then(function (res) {
          _this.$message({
            type: "success",
            message: "Khôi phục mật khẩu thành công"
          });
        });
      });
    },
    handleEdit: function handleEdit(row) {
      this.$refs["user-dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["user-dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this2 = this;

      this.$confirm("Tài khoản này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          info: {
            isBlock: false
          },
          roleId: row.role && row.role.id
        };
        user.update(row.id, data).then(function () {
          row.isBlock = false;

          _this2.$message({
            type: "success",
            message: "Mở khóa tài khoản thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this3 = this;

      this.$confirm("Tài khoản này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          info: {
            isBlock: true
          },
          roleId: row.role && row.role.id
        };
        user.update(row.id, data).then(function () {
          row.isBlock = true;

          _this3.$message({
            type: "success",
            message: "Khóa tài khoản thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this4 = this;

      this.listLoading = true;
      user.getList(this.listQuery).then(function (res) {
        _this4.tableData = res.data.data;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    }
  }
};
exports.default = _default;