"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PieChart = _interopRequireDefault(require("../PieChart"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["data"],
  components: {
    PieChart: _PieChart.default
  },
  methods: {
    initChart: function initChart() {
      this.$refs.chart.initChart();
    }
  }
};
exports.default = _default;