var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách khách hàng",
        visible: _vm.visible,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "Loại" },
              on: { change: _vm.getTableData },
              model: {
                value: _vm.listQuery.category,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "category", $$v)
                },
                expression: "listQuery.category",
              },
            },
            _vm._l(_vm.CustomerCategory, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item, value: index },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.listQuery.category == "BADGE"
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "Cấp bậc" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.level,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "level", $$v)
                    },
                    expression: "listQuery.level",
                  },
                },
                _vm._l(_vm.badgeData, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.level },
                  })
                }),
                1
              )
            : _vm.listQuery.category == "OFFLINE"
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "Số ngày không hoạt động" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.offlineDays,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "offlineDays", $$v)
                    },
                    expression: "listQuery.offlineDays",
                  },
                },
                [
                  _c("el-option", { attrs: { value: null, label: "Tất cả" } }),
                  _vm._v(" "),
                  _vm._l(_vm.enumOffline, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item, value: index },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "name", label: "Họ tên" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "Điểm", prop: "point" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Cấp", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v("Cấp " + _vm._s(row.level))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "gender", label: "Giới tính" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.enumSex[row.gender]))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "dayOfBirth", label: "Ngày sinh" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.process } },
            [_vm._v("Thực thi")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }