"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commission = commission;
exports.create = create;
exports.findAll = findAll;
exports.orderFood = orderFood;
exports.resetPassword = resetPassword;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/store",
    method: "get",
    params: listQuery
  });
}

function orderFood(id, listQuery) {
  return (0, _request.default)({
    url: "/store/".concat(id, "/orderFood"),
    method: "get",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/store",
    method: "post",
    data: data
  });
}

function update(storeId, data) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/update"),
    method: "post",
    data: data
  });
}

function resetPassword(storeId, data) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/password/update"),
    method: "post",
    data: data
  });
}

function commission(storeId, params) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/commission"),
    params: params
  });
}