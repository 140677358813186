"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _enterprise = require("@/api/enterprise");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    enterpriseId: Number
  },
  watch: {
    enterpriseId: function enterpriseId() {
      this.fetchData();
    }
  },
  data: function data() {
    return {
      data: [],
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      _enterprise.enterpriseApi.findRate(this.enterpriseId, this.listQuery).then(function (res) {
        _this.data = res.data.rates;
        _this.total = res.data.total;
      });
    }
  }
};
exports.default = _default;