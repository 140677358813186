"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

require("core-js/modules/es6.number.constructor");

var _vuex = require("vuex");

var _storeRegionalFood = require("@/api/store-regional-food");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    },
    storeRegionId: Number
  },
  data: function data() {
    return {
      dialogLoading: false,
      tempData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên món",
          trigger: "submit"
        },
        originPrice: {
          required: true,
          message: "Vui lòng nhập giá gốc",
          trigger: "submit"
        },
        thumbnail: {
          required: true,
          message: "Vui lòng chọn ảnh",
          trigger: "submit"
        }
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.dialogLoading = false;
      this.$set(this.tempData, "thumbnail", res.data.path);
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }

      return isJPG;
    },
    editData: function editData() {
      var _this = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            storeRegionalFood: _this.tempData
          };
          _this.loadingButton = true;

          _storeRegionalFood.storeRegionalFoodApi.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                title: "Thông báo",
                type: "success",
                message: "Cập nhật món thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();

        _this2.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].resetFields();

        _this3.tempData = {
          type: "",
          openTime: "",
          closeTime: "",
          lat: 0,
          long: 0
        };
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          if (!_this4.tempData.finalPrice) {
            _this4.tempData.finalPrice = _this4.tempData.originPrice;
          }

          var data = {
            storeRegionalFood: _this4.tempData,
            storeRegionalId: _this4.storeRegionId
          };
          _this4.loadingButton = true;

          _storeRegionalFood.storeRegionalFoodApi.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                title: "Thông báo",
                type: "success",
                message: "Tạo món thành công"
              });

              _this4.dialogVisible = false;

              _this4.$refs["dataForm"].clearValidate();

              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    }
  }
};
exports.default = _default;