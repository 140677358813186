var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductTable", {
        attrs: {
          "table-data": _vm.data,
          "list-loading": _vm.listLoading,
          "handle-edit": _vm.handleEdit,
          "handle-block": _vm.handleBlock,
          "handle-change-publish": _vm.handleChangePublish,
          "handle-change-lv": _vm.handleChangeLv,
        },
      }),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("ProductDialog", {
        ref: "dialog",
        on: { "submit:success": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }