var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "book-thumbnail" },
    [
      _c(
        "div",
        [
          _c(
            "el-upload",
            {
              staticClass: "upload-demo",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                action: _vm.$baseUrl + "/product/upload",
                "on-preview": _vm.handlePreview,
                "on-remove": _vm.handleRemove,
                "on-success": _vm.uploadListSuccess,
                "file-list": _vm.fileList,
                headers: { token: _vm.token },
                multiple: "",
                "list-type": "picture-card",
                accept: "image/*",
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("\n        Click to upload\n      "),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "el-upload__tip",
                attrs: { slot: "tip" },
                slot: "tip",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "Xem ảnh",
            visible: _vm.previewModal,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: {
                width: "350px",
                height: "300px",
                "object-fit": "contain",
              },
              attrs: { src: _vm.imageSelected, alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.previewModal = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }