"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var driver = _interopRequireWildcard(require("@/api/driver"));

var _date = require("@/utils/date");

var _format = require("@/utils/format");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _DriverDialog = _interopRequireDefault(require("./components/DriverDialog"));

var _enum = require("@/utils/enum");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    DriverDialog: _DriverDialog.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCities: function addressCities(state) {
      return state.address.cities;
    },
    user: function user(state) {
      return state.user.info;
    }
  })),
  data: function data() {
    return {
      DriverStatus: _enum.DriverStatus,
      VehicleType: _enum.VehicleType,
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    changeStatus: function changeStatus(row, val) {
      var _this = this;

      if (_enum.DriverStatus[val] == _enum.DriverStatus.HAVING_ORDER) {
        this.$notify.error({
          title: "Lỗi",
          message: "Không thể chuyển qua trạng thái này!"
        });
        return;
      }

      this.listLoading = true;
      driver.update(row.id, {
        driver: {
          status: val
        }
      }).then(function () {
        row.status = val;

        _this.$notify.success({
          title: "Thông báo",
          message: "Thành công!"
        });
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleReset: function handleReset(row) {
      var _this2 = this;

      this.$prompt("Nhập mật khẩu mới để khôi phục", "Khôi phục mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy"
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          newPassword: value
        };
        driver.resetPassword(row.id, data).then(function (res) {
          _this2.$notify({
            type: "success",
            message: "Khôi phục mật khẩu thành công"
          });
        });
      });
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this3 = this;

      this.$confirm("Tài khoản tài xế này sẽ mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          driver: {
            isBlock: false
          }
        };
        driver.update(row.id, data).then(function () {
          row.isBlock = false;

          _this3.$message({
            type: "success",
            message: "Mở khóa tài khoản thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this4 = this;

      this.$confirm("Tài khoản tài xế này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          driver: {
            isBlock: true
          }
        };
        driver.update(row.id, data).then(function () {
          row.isBlock = true;

          _this4.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this5 = this;

      this.listLoading = true;
      driver.findAll(this.listQuery).then(function (res) {
        _this5.tableData = res.data.data;
        _this5.total = res.data.total;
      }).finally(function () {
        _this5.listLoading = false;
      });
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    }
  }
};
exports.default = _default;