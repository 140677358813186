var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "upload-control" } },
    [
      _c(
        "el-upload",
        {
          staticStyle: { margin: "0 auto" },
          style: "width:" + _vm.width,
          attrs: {
            name: "file",
            drag: "",
            multiple: "",
            "before-upload": _vm.beforeUpload,
            action: _vm.baseUrl + _vm.uploadUrl,
            "on-success": _vm.handleSuccess,
            "show-file-list": false,
            headers: { token: _vm.token },
            "on-progress": _vm.onProgress,
            "on-error": _vm.onError,
          },
        },
        [
          _vm.isProgressing
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.percentUpload },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isProgressing
            ? [
                _vm.pathImage
                  ? _c("img", {
                      staticClass: "image",
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "object-fit": "contain",
                      },
                      attrs: { src: _vm.baseUrlMedia + _vm.pathImage, alt: "" },
                    })
                  : [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("\n          Kéo thả vào đây hoặc\n          "),
                        _c("em", [_vm._v("click vào để upload")]),
                      ]),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }