"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var api = _interopRequireWildcard(require("@/api/reward-driver"));

var _RewardDriverModal = _interopRequireDefault(require("./components/RewardDriverModal"));

var _format = require("@/utils/format");

var _date = require("@/utils/date");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    RewardDriverModal: _RewardDriverModal.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    stripHtml: function stripHtml(s) {
      return (0, _utils.stripHtml)(s);
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleUpdate(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleCreate();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleBlock: function handleBlock(row) {
      var _this = this;

      this.$confirm("Cấp bậc này sẽ bị xoá, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        api.remove(row.id).then(function () {
          row.isShow = true;

          _this.$message({
            type: "success",
            message: "Thành công"
          });

          _this.getTableData();
        });
      });
    },
    getTableData: function getTableData() {
      var _this2 = this;

      this.listLoading = true;
      api.findAll(this.listQuery).then(function (res) {
        _this2.tableData = res.data.rewardDrivers;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;