"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _productCategory = require("@/api/product-category");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogLoading: false,
      form: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        name: {
          required: true,
          message: "Trường này bắt buộc",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    editData: function editData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var data = {
            productCategory: _this.form
          };
          _this.loadingButton = true;

          _productCategory.productCategoryApi.update(_this.form.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("submit:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["form"].clearValidate();

        _this2.form = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this3.$refs["form"].resetFields();

        _this3.$refs["form"].clearValidate();

        _this3.form = {};
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var data = {
            productCategory: _this4.form
          };
          _this4.loadingButton = true;

          _productCategory.productCategoryApi.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo thành công"
              });

              _this4.dialogVisible = false;

              _this4.$emit("submit:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    }
  }
};
exports.default = _default;