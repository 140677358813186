"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _product = require("@/api/product");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    productId: {
      type: Number,
      require: true
    }
  },
  data: function data() {
    return {
      data: [],
      total: 0,
      query: {
        page: 1,
        limit: 10
      }
    };
  },
  watch: {
    productId: {
      handler: function handler(val) {
        this.query.page = 1;
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      _product.productApi.findAllViewCount(this.productId, this.query).then(function (res) {
        _this.data = res.data.views;
        _this.total = res.data.total;
      });
    }
  }
};
exports.default = _default;