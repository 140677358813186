var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("Thành viên cấp trên")]),
              _vm._v(" "),
              _c("el-tree", {
                attrs: {
                  "default-expand-all": true,
                  data: _vm.parent,
                  props: _vm.defaultProps,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("Thành viên cấp dưới")]),
              _vm._v(" "),
              _c("el-tree", {
                attrs: {
                  "default-expand-all": true,
                  data: _vm.children,
                  props: _vm.defaultProps,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }