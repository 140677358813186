import { render, staticRenderFns } from "./OrderSharingDialog.vue?vue&type=template&id=5ad17024&"
import script from "./OrderSharingDialog.vue?vue&type=script&lang=js&"
export * from "./OrderSharingDialog.vue?vue&type=script&lang=js&"
import style0 from "./OrderSharingDialog.vue?vue&type=style&index=0&id=5ad17024&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ad17024')) {
      api.createRecord('5ad17024', component.options)
    } else {
      api.reload('5ad17024', component.options)
    }
    module.hot.accept("./OrderSharingDialog.vue?vue&type=template&id=5ad17024&", function () {
      api.rerender('5ad17024', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/OrderSharingDialog.vue"
export default component.exports