"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _enterpriseStore = require("@/api/enterprise-store");

var _StoreModal = _interopRequireDefault(require("./StoreModal"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    StoreModal: _StoreModal.default
  },
  props: {
    enterpriseId: Number
  },
  data: function data() {
    return {
      data: [],
      indexSelected: null,
      loading: false
    };
  },
  watch: {
    enterpriseId: function enterpriseId() {
      this.fetchData();
    }
  },
  methods: {
    handleCreate: function handleCreate() {
      this.$refs.storeModal.handleCreate();
    },
    handleUpdate: function handleUpdate(row, index) {
      this.indexSelected = index;
      this.$refs.storeModal.handleUpdate(row);
    },
    fetchData: function fetchData() {
      var _this = this;

      this.loading = true;

      _enterpriseStore.enterpriseStoreApi.findAll({
        enterpriseId: this.enterpriseId
      }).then(function (res) {
        _this.data = res.data.enterpriseStores;
      }).finally(function () {
        return _this.loading = false;
      });
    }
  }
};
exports.default = _default;