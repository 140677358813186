var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        staticClass: "filter-item",
        attrs: {
          type: "daterange",
          "range-separator": "To",
          format: "dd/MM/yyyy",
          "value-format": "yyyy-MM-dd",
          "start-placeholder": "Ngày bắt đầu",
          "end-placeholder": "Ngày kết thúc",
        },
        on: { change: _vm.getData },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            icon: "el-icon-download",
            loading: _vm.loadingDownload,
          },
          on: { click: _vm.downloadReport },
        },
        [_vm._v("Tải xuống báo cáo")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "utensils", size: "3x" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Đơn hàng thức ăn"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Tổng")]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.data.totalOrderFood,
                      duration: 3200,
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v("Hôm nay:")]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.data.totalOrderFoodToday,
                      duration: 3200,
                    },
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Chiết khấu t.xế")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.data.orderFoodCommission,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Thu nhập t.xế")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.data.orderFoodIncome,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Tổng doanh thu")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val":
                            _vm.data.orderFoodCommission +
                            _vm.data.orderFoodIncome,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "truck", size: "3x" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Đơn hàng giao hàng"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Tổng")]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.data.totalOrderDelivery,
                      duration: 3200,
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v("Hôm nay:")]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.data.totalOrderDeliveryToday,
                      duration: 3200,
                    },
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Chiết khấu t.xế")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.data.orderDeliveryCommission,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Thu nhập t.xế")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.data.orderDeliveryIncome,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Tổng doanh thu")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val":
                            _vm.data.orderDeliveryCommission +
                            _vm.data.orderDeliveryIncome,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "motorcycle", size: "3x" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Đơn hàng di chuyển"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Tổng")]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.data.totalOrderTransport,
                      duration: 3200,
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v("Hôm nay:")]),
                  _vm._v(" "),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.data.totalOrderTransportToday,
                      duration: 3200,
                    },
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Chiết khấu t.xế")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.data.orderTransportCommission,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Thu nhập t.xế")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.data.orderTransportIncome,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Tổng doanh thu")]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val":
                            _vm.data.orderTransportIncome +
                            _vm.data.orderTransportCommission,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderFood", { attrs: { date: _vm.date } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderDelivery", { attrs: { date: _vm.date } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderTransport", { attrs: { date: _vm.date } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("TotalOrderType", {
                  ref: "total-type-order",
                  attrs: { data: _vm.dataChart },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }