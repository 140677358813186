exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"primaryColor": "#1b75bc",
	"menuText": "#9aaec5",
	"menuActiveText": "#1596d2",
	"subMenuActiveText": "#fff",
	"menuBg": "#001429",
	"menuHover": "#001429",
	"subMenuBg": "#001429",
	"subMenuHover": "#198fff",
	"sideBarWidth": "260px"
};