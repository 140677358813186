var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "driver",
        top: "30px",
        title: _vm.dialogStatus == "add" ? "Thêm tài xế" : "Cập nhật tài xế",
        visible: _vm.dialogVisible,
        width: _vm.dialogWith,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Thông tin", name: "info" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        disabled: _vm.dialogDisabled,
                        rules: _vm.rules,
                        model: _vm.tempData,
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Họ tên", prop: "name" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "name", $$v)
                                      },
                                      expression: "tempData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Số điện thoại",
                                    prop: "phone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.tempData,
                                          "username",
                                          _vm.tempData.phone
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.tempData.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "phone", $$v)
                                      },
                                      expression: "tempData.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Nick name",
                                    prop: "nickname",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.nickname,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "nickname", $$v)
                                      },
                                      expression: "tempData.nickname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Ngày sinh",
                                    prop: "dayOfBirth",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      "value-format": "dd/MM/yyyy",
                                      type: "date",
                                      placeholder: "Chọn ngày sinh",
                                    },
                                    model: {
                                      value: _vm.tempData.dayOfBirth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "dayOfBirth",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.dayOfBirth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tài khoản",
                                    prop: "username",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "username", $$v)
                                      },
                                      expression: "tempData.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dialogStatus == "add"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Mật khẩu",
                                        prop: "password",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tempData,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "tempData.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Biển số",
                                    prop: "licensePlate",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.licensePlate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "licensePlate",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.licensePlate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tên xe",
                                    prop: "vehicleName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.vehicleName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "vehicleName",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.vehicleName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Loại xe",
                                    prop: "vehicleType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.tempData.vehicleType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tempData,
                                            "vehicleType",
                                            $$v
                                          )
                                        },
                                        expression: "tempData.vehicleType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.VehicleType,
                                      function (label, value) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: value,
                                            attrs: { label: value },
                                          },
                                          [_vm._v(_vm._s(label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              (_vm.dialogStatus == "add" &&
                                _vm.user.role &&
                                !_vm.user.role.isAdmin &&
                                !_vm.user.addressCity) ||
                              (_vm.user.role && _vm.user.role.isAdmin)
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tỉnh thành",
                                        prop: "addressCityId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "",
                                          },
                                          model: {
                                            value: _vm.tempData.addressCityId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempData,
                                                "addressCityId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tempData.addressCityId",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "Tất cả",
                                              value: 0,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.addressCities,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.nameWithType,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dialogStatus != "add"
                            ? _c(
                                "el-col",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "Số dư: " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.tempData.balance
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    disabled: _vm.dialogDisabled,
                                    name: "file",
                                    drag: "",
                                    "before-upload": _vm.beforeUpload,
                                    action: _vm.baseUrl + "/driver/upload",
                                    "on-success": _vm.handleAvatarSuccess,
                                    "show-file-list": false,
                                    headers: { token: _vm.token },
                                    "on-error": _vm.errorUpload,
                                  },
                                },
                                [
                                  !_vm.tempData.avatar
                                    ? [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(
                                              "\n                    Kéo thả vào đây hoặc\n                    "
                                            ),
                                            _c("em", [
                                              _vm._v("click vào để upload"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src:
                                            _vm.baseUrlMedia +
                                            _vm.tempData.avatar,
                                          alt: "",
                                        },
                                      }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tên ngân hàng" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.tempData.bankName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "bankName", $$v)
                                      },
                                      expression: "tempData.bankName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "Chi nhánh" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.tempData.bankBranch,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "bankBranch",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.bankBranch",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tên chủ thẻ" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.tempData.holderName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "holderName",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.holderName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "Số tài khoản" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.tempData.bankNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "bankNumber",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.bankNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        name: "order-history",
                        label: "Lịch sử đơn hàng",
                      },
                    },
                    [
                      _c("OrderHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: { name: "pay-history", label: "Lịch sử nạp/rút" },
                    },
                    [
                      _c("PayHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        name: "transaction-history",
                        label: "Tài khoản",
                      },
                    },
                    [
                      _c("TransactionHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _vm.tabName == "info"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }