"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll() {
  return (0, _request.default)({
    url: '/orderType'
  });
}

function create(data) {
  return (0, _request.default)({
    url: '/orderType',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: "/orderType/".concat(id, "/update"),
    method: 'post',
    data: data
  });
}