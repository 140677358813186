var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductQuestionTable", {
        attrs: {
          data: _vm.data,
          total: _vm.total,
          "list-query": _vm.listQuery,
          "on-pagination": _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }