"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Store = _interopRequireDefault(require("./components/Store"));

var _AnnualFee = _interopRequireDefault(require("./components/AnnualFee"));

var _AnnualCommission = _interopRequireDefault(require("./components/AnnualCommission"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Store: _Store.default,
    AnnualFee: _AnnualFee.default,
    AnnualCommission: _AnnualCommission.default
  },
  data: function data() {
    return {
      fetchDataFee: false,
      fetchDataCommission: false
    };
  },
  methods: {
    updateListFeePaid: function updateListFeePaid(value) {
      this.fetchDataFee = value;
    },
    updateListCommissionPaid: function updateListCommissionPaid(value) {
      this.fetchDataCommission = value;
    },
    updateStore: function updateStore(value) {
      this.fetchDataCommission = value;
      this.fetchDataFee = value;
    }
  }
};
exports.default = _default;