"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _depositCustomer = require("@/api/deposit-customer");

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Dialog = _interopRequireDefault(require("./components/Dialog"));

var _format = require("@/utils/format");

var _moment = _interopRequireDefault(require("moment"));

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    DepositDialog: _Dialog.default
  },
  data: function data() {
    return {
      DepositDriverStatus: _enum.DepositDriverStatus,
      DepositDriverType: _enum.DepositDriverType,
      listLoading: false,
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      sum: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this = this;

      this.listLoading = true;
      var query = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        from: this.date[0],
        to: this.date[1]
      });

      _depositCustomer.depositCustomerApi.findAll(query).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
        _this.sum = res.data.sum;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;