var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PieChart", { ref: "chart", attrs: { dataChart: _vm.data } }),
      _vm._v(" "),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v("Tổng quan số lượng các loại đơn"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }