"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancel = cancel;
exports.cashback = cashback;
exports.findAll = findAll;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orderRegional",
    method: "get",
    params: listQuery
  });
}

function cancel(orderId, data) {
  return (0, _request.default)({
    url: "/orderRegional/".concat(orderId, "/cancel"),
    method: "post",
    data: data
  });
}

function cashback(orderId) {
  return (0, _request.default)({
    url: "/orderRegional/".concat(orderId, "/cashback"),
    method: "post"
  });
}