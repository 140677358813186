var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm code, tên, sđt",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Chọn ngày")]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "To",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Loại đơn" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: null, label: "Trạng thái" },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.ORDER_DELIVERY_STATUS, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item, value: index },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.user.role.isAdmin
            ? _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "" },
                      on: { change: _vm.getTableData },
                      model: {
                        value: _vm.listQuery.addressCityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "addressCityId", $$v)
                        },
                        expression: "listQuery.addressCityId",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Tất cả", value: undefined },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.addressCities, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.nameWithType, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng chiết khấu tài xế:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalRevenue))),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng doanh thu tài xế:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalIncome))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đơn hàng", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" },
                      },
                      [
                        _c(
                          "li",
                          [
                            _c("b", [_vm._v("Code:")]),
                            _vm._v(" " + _vm._s(row.code) + " -\n            "),
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "mini",
                                  type: _vm.tagOptions[row.status].type,
                                  effect: _vm.tagOptions[row.status].effect,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.ORDER_DELIVERY_STATUS[row.status]
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          [
                            _c("b", [_vm._v("Khách hàng:")]),
                            _vm._v(" "),
                            !row.customer
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewCustomer(row.customer)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.customer.name) +
                                        " - " +
                                        _vm._s(row.customer.phone) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          [
                            _c("b", [_vm._v("Tài xế:")]),
                            _vm._v(" "),
                            !row.driver
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDriver(row.driver)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.driver.name) +
                                        " - " +
                                        _vm._s(row.driver.phone) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("li", [
                          _c("b", [_vm._v("Tỉnh thành:")]),
                          _vm._v(" "),
                          !row.addressCity
                            ? _c("span", [_vm._v("--")])
                            : _c("span", [
                                _vm._v(_vm._s(row.addressCity.name)),
                              ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Thông tin gửi",
              prop: "code",
              "min-width": "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.customer
                      ? _c("span", [_vm._v("--")])
                      : [
                          _c(
                            "ul",
                            {
                              staticClass: "condition-list",
                              staticStyle: { "padding-left": "10px" },
                            },
                            [
                              _c(
                                "li",
                                [
                                  _c("b", [_vm._v("Họ tên:")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewCustomer(row.customer)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.customer.name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("li", [
                                _c("b", [_vm._v("Địa chỉ:")]),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.startAddress) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Thông tin nhận",
              prop: "code",
              "min-width": "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" },
                      },
                      [
                        _c("li", [
                          _c("b", [_vm._v("Họ tên:")]),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.receiverName) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("b", [_vm._v("Số điện thoại:")]),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.receiverPhone) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("b", [_vm._v("Địa chỉ:")]),
                          _vm._v(
                            "\n            " +
                              _vm._s(row.endAddress) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Số lượng hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.orderDeliveryDetails.length))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              "min-width": "300px",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Khoảng cách:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatNumberVN(row.distance)) +
                              " Km\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Số tiền theo Km:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatCurrency(row.moneyDistance)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Số tiền tip:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatCurrency(row.tip)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Khuyến mãi:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "- " + _vm._s(_vm.formatCurrency(row.moneyDiscount))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền:")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Doanh thu tài xế:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " + _vm._s(_vm.formatCurrency(row.moneyIncome))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Phương thức thanh toán:")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.paymentType == "POINT" ? "Điểm" : "Tiền mặt"
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Chiết khấu tài xế", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == "COMPLETE"
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatCurrency(
                                  row.moneyFinal - row.moneyIncome
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.showStatusCol
            ? _c("el-table-column", {
                attrs: { label: "Hành động" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                size: "mini",
                                disabled:
                                  _vm.ORDER_DELIVERY_STATUS[row.status] ==
                                    _vm.ORDER_DELIVERY_STATUS.COMPLETE ||
                                  _vm.ORDER_DELIVERY_STATUS[row.status] ==
                                    _vm.ORDER_DELIVERY_STATUS.CANCEL ||
                                  _vm.ORDER_DELIVERY_STATUS[row.status] ==
                                    _vm.ORDER_DELIVERY_STATUS.CUSTOMER_CANCEL ||
                                  _vm.ORDER_DELIVERY_STATUS[row.status] ==
                                    _vm.ORDER_DELIVERY_STATUS.DRIVER_CANCEL,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeStatus(row)
                                },
                              },
                            },
                            [_vm._v("Huỷ đơn")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3070361690
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("CustomerDialog", {
        ref: "customer-dialog",
        attrs: { dialogDisabled: true },
      }),
      _vm._v(" "),
      _c("driver-dialog", {
        ref: "driver-dialog",
        attrs: { dialogDisabled: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }