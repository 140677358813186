"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _QuestionPending = _interopRequireDefault(require("./components/QuestionPending"));

var _QuestionAll = _interopRequireDefault(require("./components/QuestionAll"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    QuestionPending: _QuestionPending.default,
    QuestionAll: _QuestionAll.default
  }
};
exports.default = _default;