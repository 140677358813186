"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _BarChart = _interopRequireDefault(require("../BarChart"));

var dashbord = _interopRequireWildcard(require("@/api/dashboard"));

var _moment = _interopRequireDefault(require("moment"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BarChart: _BarChart.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    addressCityId: function addressCityId(state) {
      return state.address.citySelectedId;
    }
  })),
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
  },
  watch: {
    addressCityId: function addressCityId() {
      this.getDataChart();
    }
  },
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;

      var listQuery = {
        from: this.date[0],
        to: this.date[1],
        addressCityId: this.addressCityId
      };
      dashbord.customerChart(listQuery).then(function (res) {
        _this.dataChart = res.data.report;

        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};
exports.default = _default;