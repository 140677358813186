"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      imageSelected: "",
      previewModal: false,
      fileList: [],
      thumbnail: ""
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  methods: {
    uploadListSuccess: function uploadListSuccess(response, file, fileList) {
      this.fileList = fileList;
    },
    onChooseMedia: function onChooseMedia(thumbnail) {
      this.thumbnail = thumbnail;
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.thumbnail = res.data.path;
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("Image picture must be image format!");
      }

      if (!isLt2M) {
        this.$message.error("Image picture size can not exceed 5MB!");
      }

      return isJPG && isLt2M;
    },
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview: function handlePreview(file) {
      this.imageSelected = file.url;
      this.previewModal = true;
    }
  }
};
exports.default = _default;