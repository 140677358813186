"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerChart = customerChart;
exports.driver = driver;
exports.getDashboard = getDashboard;
exports.getOrder = getOrder;
exports.orderDeliveryChart = orderDeliveryChart;
exports.orderFoodChart = orderFoodChart;
exports.orderTransportChart = orderTransportChart;
exports.top5Customer = top5Customer;
exports.top5Driver = top5Driver;

var _request = _interopRequireDefault(require("@/utils/request"));

function getDashboard(params) {
  return (0, _request.default)({
    url: "/dashboard",
    method: "get",
    params: params
  });
}

function getOrder(params) {
  return (0, _request.default)({
    url: "/dashboard/order",
    method: "get",
    params: params
  });
}

function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customer/last30",
    method: "get",
    params: listQuery
  });
}

function orderFoodChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderFood/last30",
    method: "get",
    params: listQuery
  });
}

function orderDeliveryChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderDelivery/last30",
    method: "get",
    params: listQuery
  });
}

function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport/last30",
    method: "get",
    params: listQuery
  });
}

function top5Driver(params) {
  return (0, _request.default)({
    url: "/dashboard/driver/top5",
    method: "get",
    params: params
  });
}

function driver(params) {
  return (0, _request.default)({
    url: "/dashboard/driver",
    method: "get",
    params: params
  });
}

function top5Customer() {
  return (0, _request.default)({
    url: "/dashboard/customer/top5",
    method: "get"
  });
}