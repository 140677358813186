"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productApi = {
  findAll: function findAll(listQuery) {
    return (0, _request.default)({
      url: "/product",
      method: "get",
      params: listQuery
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/product",
      method: "post",
      data: data
    });
  },
  update: function update(productId, data) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/update"),
      method: "post",
      data: data
    });
  },
  remove: function remove(productId) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/delete"),
      method: "post"
    });
  },
  findAllStore: function findAllStore(productId) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/store")
    });
  },
  findAllQuestion: function findAllQuestion(productId, params) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/question"),
      params: params
    });
  },
  updateQuality: function updateQuality(productId, data) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/quality"),
      method: "post",
      data: data
    });
  },
  findAllRate: function findAllRate(productId, params) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/rate"),
      params: params
    });
  },
  findAllViewCount: function findAllViewCount(productId, params) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/views"),
      params: params
    });
  },
  changeLv: function changeLv(productId, data) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/level"),
      method: "post",
      data: data
    });
  },
  answerQuestion: function answerQuestion(questionId, data) {
    return (0, _request.default)({
      url: "/product/question/".concat(questionId, "/answer"),
      method: "post",
      data: data
    });
  },
  updateStore: function updateStore(productId, data) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/store"),
      method: "post",
      data: data
    });
  },
  publish: function publish(productId) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/publish"),
      method: "post"
    });
  },
  unPublish: function unPublish(productId) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/unPublish"),
      method: "post"
    });
  },
  resetPassword: function resetPassword(productId, data) {
    return (0, _request.default)({
      url: "/product/".concat(productId, "/password/update"),
      method: "post",
      data: data
    });
  }
};
exports.productApi = productApi;