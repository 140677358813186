"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var news = _interopRequireWildcard(require("@/api/news"));

var _NewsDialog = _interopRequireDefault(require("./../components/NewsDialog"));

var _format = require("@/utils/format");

var _date = require("@/utils/date");

var _utils = require("@/utils");

var _StoreDialog = _interopRequireDefault(require("@/views/store/components/StoreDialog"));

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    NewsDialog: _NewsDialog.default,
    StoreDialog: _StoreDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null,
        type: _enum.NewsStoreType.Plus
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    viewStore: function viewStore(store) {
      this.$refs["store-dialog"].handleEdit(store);
    },
    stripHtml: function stripHtml(s) {
      return (0, _utils.stripHtml)(s);
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleUpdate(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleCreate(_enum.NewsStoreType.Plus);
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this = this;

      this.$confirm("Tin tức này sẽ được hiển thị, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          newsStore: {
            isShow: true
          }
        };
        news.update(row.id, data).then(function () {
          row.isShow = true;

          _this.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this2 = this;

      this.$confirm("Tin tức này sẽ bị ẩn, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          newsStore: {
            isShow: false
          }
        };
        news.update(row.id, data).then(function () {
          row.isShow = false;

          _this2.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;

      this.listLoading = true;
      news.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.data;
        _this3.total = res.data.total;
      }).finally(function () {
        _this3.listLoading = false;
      });
    }
  }
};
exports.default = _default;