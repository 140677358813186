"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var marketing = _interopRequireWildcard(require("@/api/marketing"));

var _enum = require("@/utils/enum");

var api = _interopRequireWildcard(require("@/api/badge"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      visible: false,
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      CustomerCategory: _enum.CustomerCategory,
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      enumOffline: {
        7: "1 tuần",
        14: "2 tuần",
        30: "1 tháng",
        60: "2 tháng"
      },
      listQuery: {
        page: 1,
        limit: 10,
        category: "ALL",
        level: 0,
        offlineDays: null
      },
      tableData: [],
      badgeData: []
    };
  },
  created: function created() {
    this.getTableData();
    this.getDataBadge();
  },
  methods: {
    process: function process() {
      this.$emit("process", this.listQuery);
      this.visible = false;
    },
    handleProcess: function handleProcess() {
      this.visible = true;
    },
    formatCurrency: function (_formatCurrency) {
      function formatCurrency(_x) {
        return _formatCurrency.apply(this, arguments);
      }

      formatCurrency.toString = function () {
        return _formatCurrency.toString();
      };

      return formatCurrency;
    }(function (n) {
      return formatCurrency(n);
    }),
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd(this.listQuery);
    },
    getTableData: function getTableData() {
      var _this = this;

      this.listLoading = true;
      marketing.findAll(this.listQuery).then(function (res) {
        _this.tableData = res.data.customer;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    getDataBadge: function getDataBadge() {
      var _this2 = this;

      api.findAll(this.listQuery).then(function (res) {
        _this2.badgeData = res.data.data;
      }).finally(function () {});
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;