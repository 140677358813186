"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _enterprise = require("@/api/enterprise");

var _vuex = require("vuex");

var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage"));

var _StoreTable = _interopRequireDefault(require("./StoreTable"));

var _EnterpriseRate = _interopRequireDefault(require("./EnterpriseRate"));

var _ProductTab = _interopRequireDefault(require("./ProductTab"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SingleImage: _SingleImage.default,
    StoreTable: _StoreTable.default,
    EnterpriseRate: _EnterpriseRate.default,
    ProductTab: _ProductTab.default
  },
  data: function data() {
    return {
      modalWidth: "700px",
      dialogLoading: false,
      tabName: "info",
      form: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        name: {
          required: true,
          message: "Trường này bắt buộc",
          trigger: "submit"
        },
        address: {
          required: true,
          message: "Trường này bắt buộc",
          trigger: "submit"
        },
        phone: {
          required: true,
          trigger: "submit",
          message: "Trường này bắt buộc"
        },
        taxCode: {
          required: true,
          message: "Trường này bắt buộc",
          trigger: "submit"
        },
        username: {
          required: true,
          message: "Trường này bắt buộc",
          trigger: "submit"
        }
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "product":
          this.modalWidth = "1200px";
          break;

        default:
          this.modalWidth = "700px";
          break;
      }
    }
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(path) {
      this.$set(this.form, "thumbnail", path);
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }

      return isJPG;
    },
    editData: function editData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var data = {
            enterprise: _this.form
          };
          _this.loadingButton = true;

          _enterprise.enterpriseApi.update(_this.form.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("submit:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.tabName = "info";
      this.$nextTick(function () {
        _this2.$refs["form"].clearValidate();

        _this2.form = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.tabName = "info";
      this.$nextTick(function () {
        _this3.$refs["form"].resetFields();

        _this3.$refs["form"].clearValidate();

        _this3.form = {};
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var data = {
            enterprise: _this4.form
          };
          _this4.loadingButton = true;

          _enterprise.enterpriseApi.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo thành công"
              });

              _this4.dialogVisible = false;

              _this4.$emit("submit:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    }
  }
};
exports.default = _default;