"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var addressApi = {
  city: function city() {
    return (0, _request.default)({
      url: "/addressCity"
    });
  }
};
exports.addressApi = addressApi;