"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SharingRequestType = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var order = _interopRequireWildcard(require("@/api/order/sharing"));

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _format = require("@/utils/format");

var _enum = require("@/utils/enum");

var _moment = _interopRequireDefault(require("moment"));

var _OrderSharingDialog = _interopRequireDefault(require("./../components/OrderSharingDialog"));

var _DriverDialog = _interopRequireDefault(require("@/views/driver/components/DriverDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var SharingRequestType = {
  Sharing: "SHARING",
  NotSharing: "NOT_SHARING"
};
exports.SharingRequestType = SharingRequestType;
var _default = {
  components: {
    Pagination: _Pagination.default,
    OrderSharingDialog: _OrderSharingDialog.default,
    DriverDialog: _DriverDialog.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      SharingRequestType: SharingRequestType,
      totalMoneyTotal: 0,
      totalRevenue: 0,
      totalIncome: 0,
      ORDER_TRANSPORT_STATUS: _enum.ORDER_TRANSPORT_STATUS,
      listLoading: false,
      total: 0,
      totalMoney: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null,
        status: null
      },
      tableData: [],
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      tagOptions: _enum.tagOptions,
      OrderTableStatus: _enum.OrderTableStatus,
      PaymentType: _enum.PaymentType
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    calcSumPrice: function calcSumPrice(data) {
      var sum = data.reduce(function (prev, cur) {
        return prev + cur.amount * cur.finalPrice;
      }, 0);
      return sum;
    },
    viewRestaurant: function viewRestaurant(store) {
      this.$refs["restaurant-dialog"].handleEdit(store);
    },
    viewDetail: function viewDetail(row) {
      this.$refs["order-dialog"].handleView(row);
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleCancel: function handleCancel(id) {
      var _this = this;

      this.$prompt("B\u1EA1n ch\u1EAFc ch\u1EAFn mu\u1ED1n h\u1EE7y \u0111\u01A1n n\xE0y?. Xin ghi l\xFD do.", "Cảnh báo", {
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
        type: "warning"
      }).then(function (_ref) {
        var value = _ref.value;
        _this.listLoading = true;
        var data = {
          reasonReject: value
        };
        order.cancel(id, data).then(function () {
          _this.$notify({
            title: "",
            type: "success",
            message: "Thay đổi thành công"
          });

          _this.getTableData();
        }).finally(function () {
          _this.listLoading = false;
        });
      });
    },
    getTotalMoney: function getTotalMoney() {
      var _this2 = this;

      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this2.totalMoney = total;
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;

      this.listLoading = true;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      order.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.orderSharings;
        _this3.total = res.data.total;
      }).finally(function () {
        _this3.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    }
  }
};
exports.default = _default;