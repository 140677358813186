var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { "prefix-icon": "el-icon-search", placeholder: "Tìm code" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableDataByDriverId($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "daterange",
              "range-separator": "To",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Ngày bắt đầu",
              "end-placeholder": "Ngày kết thúc",
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "Loại đơn" },
              on: { change: _vm.getTableDataByDriverId },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            [
              _c("el-option", { attrs: { value: null, label: "Trạng thái" } }),
              _vm._v(" "),
              _vm._l(_vm.ORDER_DELIVERY_STATUS, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: index },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getTableDataByDriverId },
            },
            [_vm._v("Tìm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng lợi nhuận:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalRevenue))),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng chiết khấu:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalIncome))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Code", prop: "code", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.status].type,
                          effect: _vm.tagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.ORDER_DELIVERY_STATUS[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Khách hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.customer
                      ? _c("span", [_vm._v("--")])
                      : _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(row.customer.name)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Điểm đi", prop: "startAddress" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Điểm đến", prop: "endAddress" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              "min-width": "300px",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Số tiền theo Km:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatCurrency(row.moneyDistance)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền:")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Doanh thu tài xế:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " + _vm._s(_vm.formatCurrency(row.moneyIncome))
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Lợi nhuận", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == "COMPLETE"
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatCurrency(
                                  row.moneyFinal - row.moneyIncome
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.showStatusCol
            ? _c("el-table-column", {
                attrs: { label: "Trạng thái" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-select",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.changeStatus(row)
                                },
                              },
                              model: {
                                value: row.status,
                                callback: function ($$v) {
                                  _vm.$set(row, "status", $$v)
                                },
                                expression: "row.status",
                              },
                            },
                            _vm._l(
                              _vm.ORDER_DELIVERY_STATUS,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index },
                                })
                              }
                            ),
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2508522987
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableDataByDriverId,
        },
      }),
      _vm._v(" "),
      _c("CustomerDialog", {
        ref: "customer-dialog",
        attrs: { "dialog-disabled": true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }