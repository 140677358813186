"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var driver = _interopRequireWildcard(require("@/api/driver"));

var _format = require("@/utils/format");

var _date = require("@/utils/date");

var _utils = require("@/utils");

var _enum = require("@/utils/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    driverId: Number
  },
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10
      },
      tableData: [],
      transactionType: _enum.TransactionType
    };
  },
  watch: {
    driverId: {
      handler: function handler(val) {
        this.getTableData();
      },
      deep: true
    }
  },
  methods: {
    stripHtml: function stripHtml(s) {
      return (0, _utils.stripHtml)(s);
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    getTableData: function getTableData() {
      var _this = this;

      this.listLoading = true;
      driver.transaction(this.driverId, this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;