"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.paid = paid;
exports.unpaid = unpaid;

var _request = _interopRequireDefault(require("@/utils/request"));

function unpaid(listQuery) {
  return (0, _request.default)({
    url: "/historyPaymentAnnualFeeStore/unpaid",
    method: "get",
    params: listQuery
  });
}

function paid(listQuery) {
  return (0, _request.default)({
    url: "/historyPaymentAnnualFeeStore/paid",
    method: "get",
    params: listQuery
  });
}

function create(id, data) {
  return (0, _request.default)({
    url: "/historyPaymentAnnualFeeStore/".concat(id),
    method: "post",
    data: data
  });
}