"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/150/150webAdmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _productQuestion = require("@/api/product-question");

var _ProductQuestionTable = _interopRequireDefault(require("./ProductQuestionTable"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ProductQuestionTable: _ProductQuestionTable.default
  },
  data: function data() {
    return {
      data: [],
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      _productQuestion.productQuestionApi.pending(this.listQuery).then(function (res) {
        _this.data = res.data.productQuestions;
        _this.total = res.data.total;
      });
    }
  }
};
exports.default = _default;