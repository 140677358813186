var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "table_dialog",
        title: "Chi tiết đơn hàng",
        visible: _vm.dialogVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            model: _vm.formData,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "ul",
                    {
                      staticClass: "food-list",
                      staticStyle: { "padding-left": "0" },
                    },
                    [
                      _c("li", { staticClass: "food-item" }, [
                        _c("div", [
                          _c("span", { staticClass: "name" }, [
                            _vm._v("Tên khách hàng:"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "amount" }, [
                            _vm._v(_vm._s(_vm.formData.customer.name)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "food-item" }, [
                        _c("div", [
                          _c("span", { staticClass: "name" }, [_vm._v("SĐT:")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "amount" }, [
                            _vm._v(_vm._s(_vm.formData.customer.phone)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "food-item" }, [
                        _c("div", [
                          _c("span", { staticClass: "name" }, [
                            _vm._v("Phương thức thanh toán:"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "amount" }, [
                            _vm._v(
                              _vm._s(_vm.PaymentType[_vm.formData.paymentType])
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.formData.reasonReject
                        ? _c("li", { staticClass: "food-item" }, [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v("Lý do từ chối:"),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "amount" }, [
                                _vm._v(_vm._s(_vm.formData.reasonReject)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Chi tiết")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "food-list",
                      staticStyle: { "padding-left": "0" },
                    },
                    _vm._l(
                      _vm.formData.orderRegionalDetails,
                      function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "food-item" },
                          [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.storeRegionalFood.name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "amount" }, [
                                _vm._v(" x " + _vm._s(item.amount)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", { staticClass: "single-price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        item.storeRegionalFood.finalPrice
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", { staticClass: "price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toThousandFilter")(
                                      item.amount * item.finalPrice
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "text-align": "right" } }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Tổng cộng:")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            _vm.calcSumPrice(_vm.formData.orderRegionalDetails)
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formData.orderRegionalBills.length > 0
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Hóa đơn")]),
                      _vm._v(" "),
                      _c("el-upload", {
                        staticClass: "upload-demo",
                        staticStyle: { "margin-top": "20px" },
                        attrs: {
                          action: "#",
                          "file-list": _vm.fileList,
                          "list-type": "picture-card",
                          accept: "image/*",
                          "auto-upload": false,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "file",
                              fn: function (ref) {
                                var file = ref.file
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "el-upload-list__item-thumbnail",
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "el-upload-list__item-thumbnail",
                                      attrs: { src: file.url, alt: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item-actions",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item-preview",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlePreview(file)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-zoom-in",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3717873800
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "Xem ảnh",
            visible: _vm.previewModal,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: {
                width: "350px",
                height: "300px",
                "object-fit": "contain",
              },
              attrs: { src: _vm.imageSelected, alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.previewModal = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }